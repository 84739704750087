import styled from 'styled-components'

export const ProgressIndicator = styled.div<{ color: string, progress: number, height?: number, opacity?: number, shadow?: boolean, rounded?: boolean }>`
  position: absolute;
  bottom: 0;
  height:${props => props.height ?? 20}px;
  left: 0;
  background-color: ${props => props.color};
  width:${props => props.progress * 100}%;
  text-align: center;
  vertical-align: middle;
  text-overflow: ellipsis;
  opacity:${props => props.opacity ?? 0.4};
  box-shadow: ${props => props.shadow ? `0px 0px 5px ${props.color}` : 'none'};
  border-top-right-radius: ${props => props.rounded ? '5px' : 0};
  border-bottom-right-radius: ${props => props.rounded ? '5px' : 0};
  //transition: width 0.265s;
  
`
