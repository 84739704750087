import { Content } from '../models'
import { v4 as uuidv4 } from 'uuid'

// this will wrap all the text nodes in spans
// however, it will NOT split them into words
// this step is necessary, because if text is split to words directly,
// and the parent element happens to have flexbox the layout will break
// splitting to words is done later
// also, this step is taken to save the original content for translation purposes

const elementsToIgnore = ['NOSCRIPT', 'IMG', 'SCRIPT', 'IFRAME', 'STYLE', 'SVG', 'HEAD', 'APPLET', 'AUDIO', 'CANVAS',
  'DATA', 'EMBED', 'OBJECT', 'FRAME', 'FRAMESET', 'MAP', 'TRACK', 'VIDEO','VAR']

const wrapTextNodesToSpans = (element: HTMLElement, originalPageContent: Content[] = []) => {
  if (isAlreadyWrapped(element)) { throw new Error('element already wrapped. This should never happen') }
  // need to copy the array as apparently operations inside foreach might manipulate it
  if(element.tagName.toLowerCase() === "input"){
    const inputElement = <HTMLInputElement>element;
    
    if (!elementsToIgnore.includes(inputElement.tagName)) {
      if(inputElement.placeholder){
        if (containsPronounciableChars(inputElement.placeholder)) { 
          inputElement.dataset.voiceIntuitiveSentence = uuidv4();
          originalPageContent.push({ 
            id:inputElement.dataset.voiceIntuitiveSentence, 
            content: inputElement.placeholder, 
            translate: true })
        }
      }
    }
    
  }
  const childNodes = [...element.childNodes]
  childNodes.forEach((childNode) => {
    if (elementsToIgnore.includes(element.tagName)) { return }
    if (childNode instanceof HTMLElement) {
      wrapTextNodesToSpans(childNode, originalPageContent)
      return
    }
    if (childNode instanceof HTMLStyleElement) return
    if (!isTextNode(childNode)) { return }
    // get the text of the element and create an id for it
    const sentence = getTextContent(childNode)
    if (!containsPronounciableChars(sentence)) { return }
    const sentenceID = uuidv4()
    
    const textSpan = createSpan(sentence, sentenceID)
    replaceTextWithSpan(childNode, textSpan)
    originalPageContent.push({ id: sentenceID, content: sentence, translate: shouldTranslate(childNode) })
  })
  return originalPageContent
}

function isTextNode (childNode: ChildNode) {
  return childNode.nodeType === Node.TEXT_NODE
}

function replaceTextWithSpan (childNode: ChildNode, textSpan: HTMLSpanElement) {
  // add the same content wrapped into a span to the text node
  childNode.parentNode?.insertBefore(textSpan, childNode)
  // remove the word itself from the text node
  childNode.textContent = ''
}
function createSpan (sentence: string, sentenceID: string) {
  const textSpan = document.createElement('span')
  textSpan.innerHTML = sentence

  textSpan.dataset.voiceIntuitiveSentence = sentenceID

  // style element
  textSpan.style.fontFamily = 'inherit'
  textSpan.style.fontWeight = 'inherit'
  textSpan.style.color = 'inherit'

  return textSpan
}

function escapeHtml (sentence: string = '') {
  sentence = sentence.replace('<', '&#60;')
  sentence = sentence.replace('>', '&#62;')
  return sentence
}

function shouldTranslate (childNode: ChildNode) {
  if (hasTranslationPreventingAttributes(childNode)) { return false }
  let p = childNode.parentNode
  while (p) {
    if (hasTranslationPreventingAttributes(p)) { return false }
    if (elementsToIgnore.includes((p as any).tagName)) { return true }
    p = p.parentNode
  }
  return true
}
function hasTranslationPreventingAttributes (node: any) {
  if (node.className?.includes('notranslate')) { return true }
  if (node.lang && node.nodeName !== 'HTML') { return true }
  return false
}

// if this sentence has already been wrapped in spans, do nothing
// this is important if widget is unmounted and mounted again
function isAlreadyWrapped (element: HTMLElement) {
  return !!element.dataset?.voiceIntuitiveSentence
}

function getTextContent (childNode: ChildNode) {
  const content = childNode.textContent ?? ''
  return escapeHtml(content)
}
export default wrapTextNodesToSpans

function containsPronounciableChars (sentence: string) {
  return !!sentence.match(/\w/)
}
