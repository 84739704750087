import { h } from 'preact'
import { PlayButton } from './buttons/PlayButton'
import styled from 'styled-components'
import { InlinePlay } from './symbols/InlinePlay'
import { useAppConfig } from '../../context/ConfigContext'

const Container = styled.div<{ margin: string }>`
  margin: ${(props) => props.margin};
  line-height:20px;
`

// this function renders the inline button that appears on the page
export function ReaderButtons () {
  const { buttonMargin, hidden, extraCSSForListenButton } = useAppConfig()

  // button appears in multiple places, this ensures it can be styled properly
  const buttonStyles = `
  height: 44px;
  line-height:20px;
  padding: 0;
  `

  return (
    <Container margin={buttonMargin}>
      <div style={{height:"44px"}}>
        {!hidden && <PlayButton extraStyles={buttonStyles.concat(extraCSSForListenButton)}>
          <InlinePlay />
        </PlayButton>}
          {/* <Audio /> probably not needed anymore as audio will be in context */}
      </div>
    </Container>
  )
}
