import styled from 'styled-components'
import { h } from 'preact'
import { Status } from '../../../models'

export const Svg = styled.svg<{ color: string }>`
  position: relative;
  top: 4.5px;
  height: 20px;
  width: 24px;
  path {
    stroke: ${(props) => props.color};
    transition: stroke, opacity 0.5s;
  }
  &.paused path:nth-child(1){
    stroke: none;
  }
  &.paused path:nth-child(2){
    stroke: none;
  }
  &.error path{
    stroke: red;
  }
  &.loading path{
    opacity:0.2;
  }
  
`
interface Props {
  color: string
  status: Status
}

export function PlayingIcon ({ color, status }: Props) {
  const duration = 1.5
  return <Svg
      width="14"
      height="17"
      viewBox="0 0 14 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={getClassName(status)}
      color={color}
  >
    <PlayingBar duration={duration} phase={0.7} position={1} status={status}/>
    <PlayingBar duration={duration} phase={0.2} position={13} status={status}/>
    <PlayingBar duration={duration} phase={0.9} position={5} status={status}/>
    <PlayingBar duration={duration} phase={0.5} position={9} status={status}/>

  </Svg>
}
interface PlayingBarProps {
  duration: number
  phase: number
  position: number
  status: Status
}

function getBarMinMax (status: Status, position: number) {
  const max = 10.6429
  const min = 1
  const playing = { min, max }
  switch (status) {
    case 'playing':
      return playing
    case 'paused':
      if (position === 1 || position === 13) { return { min: max, max } } else { return { min, max: 3 } }
    case 'loading':
    case 'error':
    case 'uninitialized':
    case 'disabled':
      return { min: max, max }
  }
}

function PlayingBar ({ position, phase, duration, status }: PlayingBarProps) {
  const { max, min } = getBarMinMax(status, position)

  return <path
      strokeWidth="2"
      strokeLinecap="round"
      d={`M${position} 16V${max}`}
  >

    {status === 'playing' && <animate
        attributeName={'d'}
        values={`M${position} 16V${max};M${position} 16V${min};M${position} 16V${max};`}
        dur={`${duration}s`}
        repeatCount={'indefinite'}
        begin={`${phase}s`}
    />}

  </path>
}

function getClassName (status: Status): string | undefined {
  switch (status) {
    case 'paused':
    case 'playing':
    case 'loading':
      return status
    default:
      return 'error'
  }
}
