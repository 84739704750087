import { h } from 'preact'
import styled from 'styled-components'
import { useHideOnClickOutside } from '../../hooks'
import { useAppConfig } from '../../context/ConfigContext'
import { usePlayerController } from '../../context/PlayerContext'
import { useLocalizations } from '../../localizations'

const SpeedSelectorContainer = styled.div<{ backgroundColor: string }>`
  display: none;
  position: fixed;
  font-family: quicksand;
  bottom: 65px;
  left: calc(50% + 45px);
  width: 150px;
  z-index: 100;
  border-radius: 8px;
  box-shadow: 0px 0px 25px #a9a9a9;
  background-color: ${(props) => props.backgroundColor};
  @media (max-width: 450px) {
    display: block;
    position: fixed;
    bottom: 70px;
    left: 0;
    z-index: -110;
    width: 100vw;
    box-shadow: 0px 0px 25px #a9a9a9;
  }
`

const InnerContainer = styled.div`
  overflow-y: scroll;
  margin-bottom: 10px;
  height: 270px;
  @media (max-width: 450px) {
    height: 200px;
    padding-bottom: 1rem;
  }
`

const Title = styled.p<{ foregroundColor: string }>`
  text-align: center;
  font-weight: 600;
  margin: 10px 15px;
  color: ${(props) => props.foregroundColor};
  white-space: nowrap;
`

const SpeedButton = styled.button<{ foregroundColor: string }>`
  font-family: quicksand;
  text-align: center;
  cursor: pointer;
  background-color: transparent;
  border: none;
  font-size: 16px;
  width: 100%;
  padding: 8px 0;
  color: ${(props) => props.foregroundColor};
  &:hover {
    background-color: #00000011;
  }
`

const speeds = [
  2.0, 1.9, 1.8, 1.7, 1.6, 1.5, 1.4, 1.3, 1.2, 1.1, 1.0, 0.9, 0.8, 0.7, 0.6,
  0.5
]

export function MobileSpeedSelector () {
  const {
    controlPanelForegroundColor,
    controlPanelBackgroundColor
  } = useAppConfig()
  const playerController = usePlayerController()
  // this hides the component when it is not clicked
  const { ref } = useHideOnClickOutside(false, () => playerController.setSpeedSelectorOpen(false))

  const lightForegroundColor = controlPanelForegroundColor + '88'
  const localizations = useLocalizations()

  return (
    <SpeedSelectorContainer
      backgroundColor={controlPanelBackgroundColor}
      id={'speedSelector'}
      ref={ref}
    >
      <Title foregroundColor={controlPanelForegroundColor}>
        {localizations.playbackSpeedTitle}
      </Title>
      <InnerContainer>
        {speeds.map((speed) => (
          <SpeedButton
            key={speed}
            foregroundColor={
              playerController.playbackSpeed === speed
                ? controlPanelForegroundColor
                : lightForegroundColor
            }
            onClick={() => playerController.changePlaybackSpeed(speed)}
          >
            {speed.toFixed(1)}
            <svg
              width="8"
              height="8"
              viewBox="0 0 13 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.52801 12.232C1.29334 12.232 1.08001 12.136 0.888006 11.944C0.696006 11.752 0.600006 11.528 0.600006 11.272C0.600006 11.016 0.696006 10.792 0.888006 10.6L10.584 0.967993C10.776 0.775993 11.0107 0.679993 11.288 0.679993C11.544 0.679993 11.7573 0.775993 11.928 0.967993C12.12 1.15999 12.216 1.37333 12.216 1.60799C12.216 1.84266 12.12 2.07733 11.928 2.31199L2.23201 11.912C2.01867 12.1253 1.78401 12.232 1.52801 12.232ZM10.488 11.944L1.14401 2.34399C0.97334 2.13066 0.888006 1.90666 0.888006 1.67199C0.888006 1.41599 0.97334 1.20266 1.14401 1.03199C1.31467 0.839994 1.51734 0.743994 1.75201 0.743994C2.02934 0.743994 2.26401 0.85066 2.45601 1.06399L11.768 10.632C11.9813 10.824 12.088 11.048 12.088 11.304C12.088 11.624 11.9813 11.8587 11.768 12.008C11.5547 12.1573 11.352 12.232 11.16 12.232C10.9467 12.232 10.7227 12.136 10.488 11.944Z"
                fill={
                  playerController.playbackSpeed === speed
                    ? controlPanelForegroundColor
                    : lightForegroundColor
                }
              />
            </svg>
          </SpeedButton>
        ))}
      </InnerContainer>
    </SpeedSelectorContainer>
  )
}
