import { PlayerController } from '../audio/PlayerController'
import { fetchIFrameContentsAsSsml } from '../iframe/fetchIFrameContentsAsSsml'
import { mountIframe } from '../iframe/mountIframe'
import { clearHighlighting } from '../highlight/highlightText'
import { PlaylistItem, PlaylistItemProps } from './PlaylistItem'
import { Playlist } from './Playlist'
import { AppConfigurations } from '../../../context/ConfigContext'
import { AddToPlaylistImage } from '../../components/symbols/AddToPlaylistImage'

interface PlaylistControllerProps {
  config: AppConfigurations
  playerController: PlayerController
  playlist: Playlist
  updatePlaylist: (playlist: Playlist) => void
  playlistWidgetOpen: boolean
  setPlaylistWidgetOpen: (open: boolean) => void
  initialized: boolean
  setInitialized: (initialized: boolean) => void
}
// TODO remove references to old playlist system
export class PlayListController implements PlaylistControllerProps {
  config: AppConfigurations
  playerController: PlayerController
  playlist: Playlist
  playlistWidgetOpen: boolean
  setPlaylistWidgetOpen: (open: boolean) => void

  updatePlaylist: (playlist: Playlist) => void
  initialized: boolean
  setInitialized: (initialized: boolean) => void

  constructor (props: PlaylistControllerProps) {
    Object.assign(this, props)
    this.updatePlaylistItemIndicators()
    //@ts-ignore
    window._vi.playListController = this;
  }

  setPlaylist (playlist: Playlist) {
    this.playlist = playlist
    this.updatePlaylist(playlist)
  }

  doPlaylistAction (fn: (playlist: Playlist) => Playlist) {
    this.setPlaylist(fn(this.playlist))
  }

  public async playNext () {
    if (this.playlist.isLastPosition()) {
      await this.doPlaylistAction(pl => pl.setPosition(0))
      await this.playerController.stop()
      return
    }
    this.doPlaylistAction(pl => pl.next())
    await this.playCurrent()
  }

  public async playPrev () {
    this.doPlaylistAction(pl => pl.prev())
    await this.playCurrent()
  }

  public async playFromIFrame (url: string) {
    mountIframe(url, async frame => await this.playIframe(frame))
  }

  private async playIframe (iframe: HTMLIFrameElement) {
    if (!iframe || !iframe.contentWindow) {
      console.error(
        'Iframe failed to appear when attempting to read content from an iframe.'
      )
      return
    }
    const ssml = await fetchIFrameContentsAsSsml(iframe, this.config)

    // TODO handle playlist read area properly
    await this.playerController.init(ssml)
  }

  async playCurrent () {
    await this.play(this.playlist.getCurrentItem())
  }

  // TODO move these to a separate class
  async play (item?: PlaylistItem) {
    if (!item) {
      await this.playerController.stop()
      return
    }
    this.playerController.loadEmptyFile()
    clearHighlighting()

    const c = item.content
    switch (c.type) {
      case 'IFRAME':
        return await this.playFromIFrame(c.url)
      case 'SSML':
        throw new Error('not implemented :' + c.type)
      case 'HTML':
        throw new Error('not implemented :' + c.type)
      case 'PLAIN':
        throw new Error('not implemented :' + c.type)
      case 'PLAIN_WITH_AUDIO':
        throw new Error('not implemented :' + c.type)
      case 'HTML_WITH_AUDIO':
        throw new Error('not implemented :' + c.type)
      case 'AUDIO':
        throw new Error('not implemented :' + c.type)
    }
  }

  pushAll (items: PlaylistItemProps[]) {
    this.doPlaylistAction(pl => pl.pushAll(items))
  }

  toggleAll (items: PlaylistItemProps[]) {
    this.doPlaylistAction(pl => pl.toggleAll(items))
  }

  async remove (index: number) {
    if (this.playlist.position === index) { await this.playerController.stop() }
    if (this.playlist.size() < 2) {
      await this.clear()
      return
    }

    this.doPlaylistAction(pl => pl.remove(index))
    if (this.playlist.isEmpty()) {
      this.playerController.setStatus('uninitialized')
      this.playerController.setWidgetOpen(false)
    }
  }

  async initAndPlay () {
    // incredibly hacky but finally works, so dont touch it. This loads first playlist item to player so that play button behaves correctly
    this.setInitialized(true)
    if (this.playlist.isEmpty() || this.playlist.position >= 0 || this.initialized) { return }
    await this.playNext()
  }

  async setPosition (index: number) {
    await this.doPlaylistAction(pl => pl.setPosition(index))
  }

  openIfNonEmpty () {
    if (!this.playlist.isEmpty() && !this.initialized) { setTimeout(() => this.setPlaylistWidgetOpen(true), 10) }
  }

  async clear () {
    await this.playerController.stop()
    this.setPlaylist(new Playlist())
    this.setPlaylistWidgetOpen(false)
    this.playerController.setWidgetOpen(false)
    this.setInitialized(false)
    await this.playerController.stop()
  }

  updatePlaylistItemIndicators () {
    document.querySelectorAll<HTMLElement>('.voiceintuitive-add-to-playlist').forEach(el => {
      const url = el.getAttribute('data-playlist-url')
      const inPlaylist = this.playlist.items?.find(item => item.content.url === url)
      const className = 'added-to-playlist'
      if (!inPlaylist){
        const newElement = <HTMLElement>AddToPlaylistImage(false,32)
        if(el.classList.contains(className)){
          newElement.style.transform = "scale(0.1)"  
          newElement.style.transformStyle="preserve-3d"
          newElement.style.transition="transform 0.8s";
          el.firstChild?.replaceWith(newElement)
        }
        setTimeout(()=>{
          newElement.style.transform = "scale(1)"
          
        },1);
        el.className = el.className.replace(className, '').trim()
      }
      else if (inPlaylist) {
        
        if(el.classList.contains(className)){
          return
        }
        
        el.className = el.className + ' ' +className
        const newElement = <HTMLElement>AddToPlaylistImage(true,32)
        el.firstChild?.replaceWith(newElement)
        newElement.style.transform="scale(0.1)" 
        newElement.style.transformStyle="preserve-3d"
        newElement.style.transition="transform 0.8s";
        setTimeout(()=>newElement.style.transform = "scale(1)",1);
        
        
      }
      
    })
  }
}
