// sometimes, especially in the case of pages created with JS the anchor element does not appear instantly
// so, we can use this function and wait for it to appear
// this promise will time out after 5000ms
const waitForElement = async (selector: string, root: HTMLElement,retriesLeft:number=10):Promise<any> =>{
  const element = document.querySelector(selector);

  if (element) {
    return element;
  }

  if (retriesLeft > 0) {
    console.log("Waiting for root element, retries left:", retriesLeft);
    await new Promise((resolve) => setTimeout(resolve, 500));
    return waitForElement(selector, root, retriesLeft - 1);
  }

  throw new Error(
    `Voice Intuitive widget failed to load after waiting for 5 seconds. Element with selector ${selector} not found. To fix this error, please add such an element somewhere in the DOM.`
  );


    // const observer = new MutationObserver((mutations) => {
    //   mutations.forEach((mutation) => {
    //     const nodes = Array.from(mutation.addedNodes)
    //     for (const node of nodes) {
    //       // @ts-expect-error
    //       if (node.matches && node.matches(selector)) {
    //         observer.disconnect()
    //         clearTimeout(timer)
    //         resolve(node)
    //         return
    //       }
    //       // @ts-expect-error
    //       if (node.querySelector) {
    //         // @ts-expect-error
    //         const el = node.querySelector(selector)
    //         if (el) {
    //           clearTimeout(timer)
    //           resolve(el)
    //           return
    //         }
    //       }
    //     }
    //   })
    // })

    // observer.observe(root, {
    //   childList: true,
    //   subtree: true
    // })
  // })
}

export default waitForElement
