import { h } from 'preact'
import { Fader } from './PlaylistTab.styles'
import { PlaylistItemView } from './PlaylistItemView'
import { usePlayerController, usePlaylistController } from '../../../context/PlayerContext'
import { useAppConfig } from '../../../context/ConfigContext'

export function PlaylistTab () {
  const {
    controlPanelForegroundColor,
    controlPanelBackgroundColor
  } = useAppConfig()

  const playerController = usePlayerController()
  const playlistController = usePlaylistController()

  return (
        <div>
            <Fader backgroundColor={controlPanelBackgroundColor}/>
            {playlistController.playlist.items.map((item, index) => {
              return (
                    <PlaylistItemView
                        item={item}
                        color={controlPanelForegroundColor}
                        key={index}
                        onPlay={async () => {
                          await playlistController.setPosition(index)
                          await playlistController.play(item)
                        }}
                        onResume={async () => await playerController.play()}
                        onPause={async () => await playerController.pause()}
                        onRemove={async () => await playlistController.remove(index)}
                        selected={index === playlistController.playlist.position}
                        playerStatus={playerController.getStatus()}
                    />
              )
            })}

        </div>
  )
}
