import { Status } from '../../../models'
import { domManipulator } from '../../../services/domManipulator'
import { ssmlGenerator } from '../ssml/ssmlGenerator'
import { SpeechStreamReader } from './SpeechStreamReader'
import { isSafari } from '../../../utils/isSafari'
import { hasPlaceholderLoaded } from '../../../utils/hasPlaceholderLoaded'
import { injectWordMetaData } from '../highlight/injectWordMetaData'
import { fetchSpeech } from './fetchSpeech'
import { highlightText } from '../highlight/highlightText'
import emptyAudioFile from '../../../assets/emptyAudioFile'
import { AppConfigurations } from '../../../context/ConfigContext'
import { RefObject } from 'preact'
import { emitGlobalEventInfo } from '../../../utils/eventEmitting'

interface PlayerControllerProps {
  status: Status
  setStatus: (status: Status) => void
  audioPlayer: RefObject<HTMLAudioElement | null>
  config: AppConfigurations
  playbackSpeed: number
  setPlaybackSpeed: (speed: number) => void
  widgetOpen: boolean
  setWidgetOpen: (open: boolean) => void
  speedSelectorOpen: boolean
  setSpeedSelectorOpen: (open: boolean) => void
  elapsed: number
  total: number
  setRefreshTrigger:(num:number)=>void
}
export class PlayerController implements PlayerControllerProps {
  status: Status
  setStatus: (status: Status) => void
  audioPlayer: RefObject<HTMLAudioElement | null>
  config: AppConfigurations
  playbackSpeed: number
  setPlaybackSpeed: (speed: number) => void
  widgetOpen: boolean
  setWidgetOpen: (open: boolean) => void
  speedSelectorOpen: boolean
  setSpeedSelectorOpen: (open: boolean) => void
  elapsed: number
  total: number
  setRefreshTrigger:(num:number)=>void
  language: string
  constructor (props: PlayerControllerProps) {
    Object.assign(this, props)
    //@ts-ignore
    window._vi.playerController = this;
  }

  public async handleClick () {
    switch (this.status) {
      case 'uninitialized':
        return await this.init()
      case 'playing':
        return await this.pause()
      case 'paused':
        return await this.play()
      default:
        console.warn('cant start playing in this state:' + this.status)
    }
  }

  public getAudioPlayer () {
    return this.audioPlayer
  }

  private getAudio () {
    if (!this.audioPlayer?.current) {
      throw new Error('audioPlayer not found')
    }
    return this.audioPlayer.current
  }

  private getReadArea () {
    const ret = document.querySelectorAll(this.config.readAreaSelector)
    if (ret == null) { throw new Error('No read area found') }
    //if (!(ret instanceof HTMLElement)) { throw new Error('read area is not a HTMLElement') }
    if (!(new Array(ret).filter(elem=>!(elem instanceof HTMLElement)).length)) { console.log(ret);throw new Error('read area is not a HTMLElement') }
    return ret
  }

  public readSsml () {
    return ssmlGenerator(
      Array.from(this.getReadArea()) as HTMLElement[],
      this.config.excludeText,
      this.config.voiceMode,
      this.config
    )
  }

  public async init (ssml?: string) {
    if (this.config.debug) { console.log('init') }
    const audio = this.getAudio()
    await audio.play()
    await audio.pause()
    if(!ssml){
      this.getReadArea().forEach((elem:HTMLElement)=>
        domManipulator.hijack(elem))
    }

    await initAudio(
      this.config,
      this,
      audio,
      ssml ?? this.readSsml()
    )
    this.setWidgetOpen(true)
  }

  public async pause () {
    await this.getAudio().pause()
    this.setStatus('paused')
    emitGlobalEventInfo('paused')
  }

  public getStatus (): Status {
    return this.status
  }

  public async play () {
    const audio = this.getAudio()

    try {
      await audio.play()
    } catch (e) {
      this.setStatus('error')
      throw e
    }
    this.setStatus('playing')
    this.setWidgetOpen(true)
    emitGlobalEventInfo('playing');
  }

  public updateHighlights () {
    const audio = this.getAudio()
    if (!audio.currentTime || !this.config.textHighlightingEnabled) { return }

    // playbackQueue.length === 0 &&
    highlightText({
      timestamp: audio.currentTime,
      delay: this.config.textHighlightDelay,
      ...this.config
    })
  }

  public async onMetaDataLoaded () {
    if (this.status === 'uninitialized' || hasPlaceholderLoaded(this.getAudio(),this.config.useFileAsEmptySoundbite ? true:false)) {
      return
    }
    await this.play()
  }

  public setTime (time: number) {
    this.getAudio().currentTime = time
    this.updateHighlights()
  }

  public getTime () {
    return this.getAudio().currentTime
  }

  public async stop () {
    this.getAudio().pause()
    this.setStatus('paused')
    this.setTime(0)
    emitGlobalEventInfo('stopped')
    if(this.config.debug){
      console.log("Voiceintuitive: playerController: PLAYER STOP WAS CALLED")
    }
  }

  loadEmptyFile () {
    if (this.getAudio()) { this.getAudio().src = emptyAudioFile(this.config.useFileAsEmptySoundbite ? true:false) }
  }

  public onTimeUpdate () {
    this.updateHighlights()
  }

  public skip (seconds: number) {
    this.setTime(this.getTime() + seconds)
  }

  public changePlaybackSpeed (speed: number) {
    this.setPlaybackSpeed(speed)
    this.getAudio().playbackRate = speed
  }

  public getProgress () {
    return this.getTime() / this.getLength()
  }

  public getLength () {
    return this.getAudio().duration
  }

  public seek (position: number) {
    this.setTime(position * this.getLength())
  }

  public reset (){
    this.setRefreshTrigger(Math.random())
    //this.setStatus('uninitialized')
    //this.loadEmptyFile()
  }
}

async function initAudio (
  {productionAPIUrl, useDevAPI,devAPIUrl, debug, useFileAsEmptySoundbite }: AppConfigurations,
  { status, setStatus, playbackSpeed }: PlayerControllerProps,
  audio: HTMLAudioElement,
  ssmlDocument: string
) {
  console.log(status)
  // if there is no audio url yet, send the data to backend, get the url and then start playing
  // else, function as a normal pause/play button
  setStatus('loading')
  emitGlobalEventInfo('loading');
  // just for benchmarking purposes
  if (debug) {
    console.log(audio)
    console.log(ssmlDocument)
    console.time('Audio retrieval timer')
  }
  const reader = new SpeechStreamReader({
    onLoadProgress (loaded, total) {
      if (debug) { console.log(`loaded:${loaded} total:${total}`) }
    },
    onReceiveFallbackUrl (url, hasReceivedStreamUrl) {
      if (isSafari() || !hasReceivedStreamUrl) { loadAudio(audio, playbackSpeed, url) }
    },
    onReceiveStreamUrl (url) {
      if (hasPlaceholderLoaded(audio,useFileAsEmptySoundbite ? true:false) && !isSafari()) { loadAudio(audio, playbackSpeed, url) }
    },
    onReceiveWordMetaData (metaData) {
      injectWordMetaData(audio, ssmlDocument, metaData)
    },
    debug:debug
  })
  try {
    const response = await fetchSpeech(ssmlDocument, productionAPIUrl,useDevAPI,devAPIUrl ? devAPIUrl:"")
    await reader.read(response)
    emitGlobalEventInfo('started');
  } catch (e) {
    console.error(e)
    setStatus('error')
    emitGlobalEventInfo('error');
  }

  if (debug) {
    console.timeEnd('Audio retrieval timer')
  }
}

function loadAudio (audio: HTMLAudioElement, playbackSpeed: number, src: string) {
  audio.src = src
  audio.load()
  audio.playbackRate = playbackSpeed
}
