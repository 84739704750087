import { h } from 'preact'
import styled from 'styled-components'
import { usePlaylistController } from '../../../context/PlayerContext'
import { useLocalizations } from '../../../localizations'
import { PlaylistSymbol } from '../symbols/PlaylistSymbol'
import { useAppConfig } from '../../../context/ConfigContext'

interface PlaylistToggleProps {
  onClick: () => void
  playlistIsOpen: boolean
}

export function TogglePlaylistButton (props: PlaylistToggleProps) {
  const { onClick, playlistIsOpen } = props
  const playlistController = usePlaylistController()
  const localizations = useLocalizations()
  const { controlPanelForegroundColor, controlPanelShadowColor } = useAppConfig()
  // if playlist has content, show the button, else render an empty div to avoid breaking the layout
  if (!playlistController.playlist.isEmpty()) {
    return (
      <Button
        aria-expanded={playlistIsOpen}
        aria-label={localizations.togglePlaylist}
        onClick={onClick}
      >
        <PlaylistSymbol />
        <NofItemsIndicator color={controlPanelForegroundColor} bgColor={controlPanelShadowColor} >
          {playlistController.playlist.size()}
        </NofItemsIndicator>
      </Button>
    )
  } else {
    return <div />
  }
}
const Button = styled.button`
  cursor: pointer;
  background-color: transparent;
  padding: 0;
  border: none;
  place-self: center start;
  position: relative;
`
const NofItemsIndicator = styled.div<{ color: string, bgColor: string }>`
  position:absolute;
  top:-8px;
  left:-8px;
  border-radius: 100px;
  width:16px;
  height:16px;
  font-weight: bold;
  font-size:10px;
  display:flex;
  justify-content: center;
  align-items: center;
  color: ${props => props.color};
  background-color: ${props => props.bgColor}
`
