import { useLocalizations } from '../localizations'
import { AddToPlaylistImage } from '../reader/components/symbols/AddToPlaylistImage'

export interface InjectAddToPlaylistProps {
  articleSelector: string
  appendChildSelector?: string
  titleChildSelector?: string
  linkChildSelector?: string
  size?: number
  iconUrl?: string
}

export function injectAddToPlaylist ({
  articleSelector,
  appendChildSelector,
  linkChildSelector = 'a',
  titleChildSelector = linkChildSelector,
  size = 32,
  iconUrl
}: InjectAddToPlaylistProps) {
  const articles = document.querySelectorAll(articleSelector)

  function inject (element: HTMLElement) {
    const parent = appendChildSelector ? element.querySelector(appendChildSelector) : element
    if (!parent) {
      console.log('failed to find parent element for addToPlayList button')
      return
    }
    const title = element.querySelector(titleChildSelector)?.textContent
    // @ts-expect-error
    const url = element.querySelector(linkChildSelector).href
    const icon = createIcon(size, iconUrl)
    const btn = document.createElement('div')
    btn.setAttribute('style', 'cursor:pointer; display: inline-block; vertical-align: middle; user-select:none;')
    btn.setAttribute('data-playlist-url', url)
    btn.className = 'voiceintuitive-add-to-playlist'
    // @ts-expect-error
    btn.onclick = () => window._vi('togglePlaylist', { title, url })
    btn.title = useLocalizations().addToPlaylist
    // btn.appendChild(icon)
    btn.innerHTML = icon
    parent.appendChild(btn)
  }
  articles.forEach(inject)
}
function createIcon (size = 32, iconUrl?: string) {
  if (iconUrl) { 
    return `<img src="${iconUrl}" width=${size} height=${size}/>` }
  else {  
    return AddToPlaylistImage(false,size).outerHTML}
}
