import styled from 'styled-components'
import { h } from 'preact'
import { ButtonContainer, Widget } from '../ReaderWidget'
import { PlayButton } from '../buttons/PlayButton'
import { PlaylistPanel } from './PlaylistPanel'
import { useState } from 'preact/hooks'
import { CurrentItemIndicator } from './CurrentItemIndicator'
import { useAppConfig } from '../../../context/ConfigContext'
import { usePlayerController, usePlaylistController } from '../../../context/PlayerContext'
import { CloseButton } from '../buttons/CloseButton'
import { TogglePlaylistButton } from '../buttons/TogglePlaylistButton'
import { ToggleSpeedSelectorButton } from '../buttons/ToggleSpeedSelectorButton'
import { MobileSpeedSelector } from '../MobileSpeedSelector'
import { ControlPanelPlay } from '../symbols/ControlPanelPlay'
import { GoBackButton } from '../buttons/GoBackButton'
import { GoForwardButton } from '../buttons/GoForward'
import { GoogleTranslateTab } from '../../../translator/components/GoogleTranslateTab'

export function PlaylistWidget () {
  const playlistController = usePlaylistController()
  const playerController = usePlayerController()
  const { controlPanelBackgroundColor, controlPanelShadowColor } = useAppConfig()
  if (!playlistController.playlistWidgetOpen) {
    return null
  }

  const [playlistOpen, setPlaylistOpen] = useState(false)
  if (playlistController.playlist.isEmpty()) { return null }
  return (
        <div>

            {playlistOpen && (
                <PlaylistPanel onClose={() => setPlaylistOpen(false)}/>
            )}

            {playerController.speedSelectorOpen && (
                <MobileSpeedSelector/>
            )}

            <Widget
                backgroundColor={controlPanelBackgroundColor}
                shadowColor={controlPanelShadowColor}
                height={85}
            >
                <CloseButton onClose={async () => await playlistController.clear()}/>
                <ExtraStyleContainer>
                    <ButtonContainer>
                            <TogglePlaylistButton
                                playlistIsOpen={playlistOpen}
                                onClick={() => setPlaylistOpen(!playlistOpen)}
                                />
                        <GoBackButton/>
                        <PlayButton extraStyles={'margin-top: -26px; place-self: center;'}>
                            <ControlPanelPlay/>
                        </PlayButton>
                        <GoForwardButton/>
                        <ToggleSpeedSelectorButton/>
                    </ButtonContainer>
                </ExtraStyleContainer>
                <CurrentItemIndicator
                    item={playlistController.playlist.getCurrentOrFirstItem()}
                    progress={playerController.getProgress()}
                />
                <GoogleTranslateTab position={"absolute"} offsetY={playlistOpen ? 570:85}/>
            </Widget>

        </div>
  ) 
}

const ExtraStyleContainer = styled.div`
margin-top:-15px;
`
