import { h } from 'preact'
import styled from 'styled-components'
import { useAppConfig } from '../../../context/ConfigContext'

const Svg = styled.svg`
  &:hover > path {
    opacity: 0.7;
  }
`
interface Props {
  size?: number
}
export function RewindSymbol ({ size = 28 }: Props) {
  const { controlPanelForegroundColor } = useAppConfig()

  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.9215 11.3233V18.3326C10.9215 18.7012 10.6094 18.9862 10.2495 18.9862C9.87773 18.9862 9.5895 18.7012 9.5895 18.3326V12.4873L9.10956 12.7609C9.00177 12.8322 8.8817 12.8559 8.77357 12.8559C8.54571 12.8559 8.32979 12.737 8.20972 12.5231C8.00574 12.2262 8.11387 11.8108 8.42564 11.6321L9.90127 10.765C9.91321 10.7532 9.93708 10.7532 9.94936 10.7413C9.97324 10.7413 9.98518 10.7177 10.0094 10.7059C10.0333 10.7059 10.0575 10.6941 10.0814 10.6941C10.0933 10.6822 10.1172 10.6822 10.1295 10.6822C10.1537 10.6704 10.1895 10.6704 10.2137 10.6704C10.2257 10.6704 10.2376 10.6704 10.2495 10.6704H10.2615C10.2973 10.6704 10.3215 10.6704 10.3454 10.6704C10.3573 10.6822 10.3812 10.6822 10.3935 10.6822C10.4293 10.6822 10.4416 10.6941 10.4655 10.7059C10.4774 10.7059 10.5013 10.7059 10.5136 10.7177C10.5497 10.7413 10.5616 10.7532 10.5855 10.7532C10.5975 10.765 10.6094 10.7768 10.6336 10.7886C10.6456 10.8005 10.6694 10.8123 10.6817 10.8241C10.7056 10.8359 10.7175 10.8596 10.7298 10.8717C10.7421 10.8839 10.7537 10.8954 10.7656 10.9194C10.7776 10.9312 10.8014 10.9548 10.8137 10.967L10.8257 10.9906C10.8257 11.0024 10.8376 11.0146 10.8376 11.0264C10.8495 11.0501 10.8615 11.0619 10.8615 11.0859C10.8734 11.1095 10.8734 11.1335 10.8854 11.1453C10.8854 11.169 10.8973 11.193 10.8973 11.2048C10.8973 11.2402 10.8973 11.2642 10.9215 11.2879C10.9215 11.2994 10.9215 11.3112 10.9215 11.3233Z"
        fill={controlPanelForegroundColor}
      />
      <path
        d="M18.6711 13.9477C19.2588 14.5179 19.5464 15.2782 19.5464 16.0386C19.5464 16.7871 19.2465 17.5474 18.6711 18.1176C18.095 18.6999 17.3275 18.9847 16.5712 18.9847C16.5474 18.9847 16.5474 18.9847 16.5474 18.9847C15.8038 18.9847 15.0236 18.6996 14.4601 18.1176C14.1842 17.8683 14.1842 17.4404 14.4601 17.1674C14.7119 16.906 15.1557 16.906 15.4197 17.1674C15.7318 17.488 16.1394 17.6423 16.5712 17.6423C16.9792 17.6423 17.3994 17.488 17.7112 17.1674C18.023 16.8584 18.1908 16.4425 18.1908 16.0386C18.1908 15.6109 18.0349 15.2069 17.7112 14.8982C17.3994 14.5773 16.9915 14.4348 16.5712 14.4348C16.1636 14.4348 15.7318 14.5773 15.4197 14.8982C15.1557 15.1593 14.7238 15.1593 14.4601 14.8982C14.412 14.8506 14.3762 14.7911 14.3281 14.7435C14.3281 14.7199 14.3281 14.708 14.3162 14.708C14.3162 14.6844 14.3043 14.6604 14.2923 14.6486C14.2923 14.6131 14.2804 14.5891 14.2804 14.5655C14.2804 14.5537 14.2684 14.5537 14.2684 14.5419C14.2565 14.4706 14.2565 14.3993 14.2684 14.3399L14.6283 11.263C14.6641 11.061 14.7723 10.8945 14.9281 10.7878C14.952 10.7759 14.964 10.7759 14.9762 10.7641C14.9885 10.7523 15.0001 10.7523 15.012 10.7405C15.1079 10.6928 15.2041 10.6692 15.3242 10.6692H18.8635C19.2472 10.6692 19.547 10.9661 19.547 11.3464C19.547 11.7028 19.2472 12.0119 18.8635 12.0119H15.9122L15.7683 13.1877C16.032 13.1164 16.3079 13.0809 16.5716 13.0809C17.3275 13.0806 18.095 13.3657 18.6711 13.9477Z"
        fill={controlPanelForegroundColor}
      />
      <path
        d="M14 0.271873C11.3612 0.271873 8.83907 0.987969 6.62973 2.34957L5.75991 1.48316L4.45348 0.199588C4.25939 -0.00544524 3.9848 -0.0537479 3.71362 0.0604221C3.4496 0.172903 3.28075 0.369154 3.28075 0.648837V5.10485C3.28075 5.27847 3.38343 5.42506 3.50998 5.55207C3.63721 5.67874 3.82686 5.74224 4.00117 5.74224H8.50308C8.78483 5.74224 8.98199 5.60848 9.09524 5.34467C9.20815 5.08526 9.1638 4.85523 8.95539 4.65763L7.56811 3.28454L7.28192 2.99912C9.30604 1.79527 11.6017 1.16294 14.0003 1.16294C21.2239 1.16294 27.1008 6.98325 27.1008 14.1375C27.1008 21.2903 21.2239 27.1093 14.0003 27.1093C6.77675 27.1093 0.899837 21.29 0.899837 14.1375C0.899837 13.8912 0.698584 13.6919 0.449918 13.6919C0.201252 13.6919 0 13.8912 0 14.1375C0 21.7814 6.28044 28 14 28C21.7199 28 28 21.7814 28 14.1375C28 6.49178 21.7199 0.271873 14 0.271873Z"
        fill={controlPanelForegroundColor}
      />
    </Svg>
  )
}
