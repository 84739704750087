import { Content } from '../../models'
import { CustomTranslator } from './CustomTranslations'

interface TranslationProps {
  originalLanguage: string
  languageCode: string
  content: Content[]
  customTranslator: CustomTranslator
  productionAPIUrl:string
  useDevAPI?: boolean
  devAPIUrl?:string
  debug?: boolean
}

export async function fetchTranslation (props: TranslationProps) {
  let ret: Record<string, string> = {}
  for (const content of splitContent(props.content)) {
    const newResultsMutating = await doRequest({ ...props, content })
    Object.keys(newResultsMutating).forEach((key:string,i:number)=>  {
      newResultsMutating[key] = 
        changeFirstLetterCapitalizationIfNeeded(
          content[i].content,
          newResultsMutating[key])
    })
    ret = { ...ret, ...newResultsMutating }
  }
  return ret
}

/**
 * split request into even smaller chunks to avoid 500 errors from BE
 * @param content
 */
const MAX_LENGTH = 18000
function splitContent (content: Content[]) {
  const ret: Content[][] = []
  let length = 0
  let cur: Content[] = []
  for (const c of content) {
    if (length > MAX_LENGTH) {
      ret.push(cur)
      cur = []
      length = 0
    }

    cur.push(c)
    length += c.content.length
  }
  if (cur.length) {
    return [...ret, cur]
  } else {
    return ret
  }
}
async function doRequest ({
  originalLanguage,
  languageCode,
  content,
  productionAPIUrl,
  useDevAPI,
  devAPIUrl,
  debug,
  customTranslator
}: TranslationProps) {
  // the latter will probably be replaced by a non-dev endpoint in the future
  let url = ""; 
  if(useDevAPI){
    if(devAPIUrl){
      url = devAPIUrl+"/translate";
    }
    else{
      if(productionAPIUrl.startsWith('https://api-stage.voicehub.fi')){
        url = 'https://api-dev.voicehub.fi/translate';
      }
      else if(productionAPIUrl.startsWith('https://api.voiceintuitive.com')){
        url = productionAPIUrl+"/translate"
      }
      else{
        url = productionAPIUrl+'/dev/translate';
      }
    }
  }
  else{
    if(productionAPIUrl.startsWith('https://voiceintuitive.azure-api.net')){
      //BUG in old backend, only dev works (i think)
      url = productionAPIUrl+'/dev/translate';
    }
    else{
      url = productionAPIUrl+'/translate';
    }
  }

  const body = {
    SourceLanguage: originalLanguage,
    TargetLanguage: languageCode,
    Content: content.map(c => toTranslateableElement(c, customTranslator)).join('\n')
  }
  // fetch translation
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Article-Path': window.location.pathname,
      'X-Target-Language':languageCode,
      'X-Source-Language':originalLanguage
    },
    body: JSON.stringify(body)
  })

  const html = await response.text()
  const parsed = parseHtml(html)
  const ret = toTranslationMap(parsed, customTranslator)
  if (debug) {
    console.log('Body:', body)
    console.log('Response:', ret)
    console.log(
      'Called function selectLanguage with language code',
      languageCode
    )
  }
  return ret
}

function parseHtml (html: string) {
  const parser = new DOMParser()
  return parser.parseFromString(html, 'text/html')
}

function changeFirstLetterCapitalizationIfNeeded (sourceMaterial:string,translated:string):string{
  if(sourceMaterial[0].toUpperCase()===sourceMaterial[0])
    return translated[0].toUpperCase().concat(translated.slice(1))
  else
    return translated
}

function toTranslationMap (doc: Document, customTranslator: CustomTranslator) {
  const ret: Record<string, string> = {}
  for (const translatedElement of doc.body.children) {
    ret[translatedElement.id] = customTranslator.doPostprocess((translatedElement as HTMLElement).innerText)
  }
  return ret
}
function toTranslateableElement (content: Content, customTranslator: CustomTranslator) {
  return `<p id=${content.id} 
            class="${content.translate ? '' : 'notranslate'}"
            >${
              customTranslator.doCustomTranslations(
                customTranslator.doPreprocess(content.content))}</p>`
}
