import { h } from 'preact'
import { StateUpdater, useEffect, useRef } from 'preact/hooks'
import styled, { StyleSheetManager } from 'styled-components'

import { TranslateIcon } from './TranslateIcon'
import { useHideOnClickOutside } from '../../hooks'
import guessLanguage from '../../services/guessLanguage'
import languages from '../translatorServices/supportedLanguages'
import { createPortal } from 'preact/compat'
import createShadowRoot from '../../services/createShadowRoot'
import { useTranslatorController } from '../../context/TranslatorContext'
import { useAppConfig } from '../../context/ConfigContext'
import { languageToCountryCode } from '../../utils/languageToCountryCode'

interface LanguageDropdownProps {
  setDropdownOpen: StateUpdater<boolean>
  onTranslate?: (languageCode: string) => void
  onReset?: () => void
  shadowRootId?: string
  buttonContainerSelector?: string
  wrapWithShadow?: boolean
}

const Dropdown = styled.div<{ 
    backgroundColor: string, 
    shadowColor: string, 
    translationButtonMarginBottom:string, 
    prependButtonsInButtonContainer:boolean,
    extraStyles?:string }>`
  position: absolute;
  border-radius: 12px;
  background-color: ${(props) => props.backgroundColor};
  box-shadow: 0px 0px 25px ${(props) => props.shadowColor};
  top: -1px;
  top: ${(props) => props.prependButtonsInButtonContainer ? 
    "-1px":
    (-29 - parseInt(props.translationButtonMarginBottom))+"px"};
  left: 0;
  width:151px;
  z-index: 1000000;
  line-height:20px;
  ${(props)=>props.extraStyles}  
`

const LanguageButton = styled.button<{
  foregroundColor: string
}>`
  border: 0;
  width: 100%;
  text-align: left;
  cursor: pointer;
  display: block;
  font-family: Quicksand;
  background-color: transparent;
  color: ${(props) => props.foregroundColor};
  padding: 0 0.75rem 0.5rem;
  font-size: 1.15rem;
  font-size: 18.2px;
  &:hover {
    opacity: 0.7;
  }
`

const Disclaimer = styled.p<{
  foregroundColor: string
}>`
  line-height:22.8px;
  font-family: Quicksand;
  background-color: transparent;
  text-align: center;
  color: ${(props) => props.foregroundColor};
  padding: 0 0.75rem 0.5rem;
  font-size: 0.95rem;
  font-size: 15.2px;
  text-transform: none;
`

const FlagSpan = styled.span<{
  flagName:string}>`
  background: url(//static.voiceintuitive.com/resources/flags/${props=>languageToCountryCode(props.flagName)}.jpg);
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  width: 18px;
  height: 14px;
  display: inline-block;
  border-radius:2.5px;
  opacity:0.9;
`

const IconContainer = styled.div`
  padding: 0.5rem;
  text-align: center;
`

const originalLanguage = guessLanguage(document)

export function LanguageDropdown (props: LanguageDropdownProps) {
  const { setDropdownOpen, onTranslate, onReset, shadowRootId } = props

  const {
    controlPanelForegroundColor,
    controlPanelBackgroundColor,
    controlPanelShadowColor,
    translationLanguages,
    translationButtonContainer,
    buttonContainerSelector,
    translationButtonMarginBottom,
    extraCSSForTranslateButton,
    prependButtonsInButtonContainer,
    useFlagSeeOriginal,
    extraCSSForTranslateDropdown
  } = useAppConfig()

  const focusedLanguageIndexRef = useRef(0);

  const translatorController = useTranslatorController()

  // use default root for translator if no root is specified in config
  const translatorContainerId = props.buttonContainerSelector ?? translationButtonContainer ?? buttonContainerSelector ?? '#voice-intuitive-root'

  const translatorShadow = createShadowRoot(
    translatorContainerId,
    shadowRootId ?? 'voice-intuitive-translator-dropdown',
    'inherit',
    prependButtonsInButtonContainer,
    extraCSSForTranslateDropdown
  )

  // used to hide the dropdown if somewhere else is clicked
  const { ref } = useHideOnClickOutside(false, setDropdownOpen)

  const filteredLanguages = () => {
    const filtered = languages.filter((language) => {
      const isInvalid =
        guessLanguage(document) === language.code ||
        originalLanguage === language.code ||
        (translationLanguages && !translationLanguages.includes(language.code))
      return !isInvalid
    })

    if (translationLanguages) {
      filtered.sort((a, b) => {
        const indexA = translationLanguages.indexOf(a.code)
        const indexB = translationLanguages.indexOf(b.code)
        return indexA - indexB
      })
    }

    return filtered
  }
  
  useEffect(() => {
    if (ref.current === null) return

    const translateLanguages: any[] = Array.from((ref.current as HTMLElement).querySelectorAll('button'))
    let buttonIndex = 0
    translateLanguages.at(0).focus()

    function handleKeyDown (event: KeyboardEvent) {
      switch (event.key) {
        case 'ArrowDown':
          event.preventDefault()
          buttonIndex += 1
          translateLanguages.at(buttonIndex % translateLanguages.length).focus()
          break
        case 'ArrowUp':
          event.preventDefault()
          buttonIndex -= 1
          translateLanguages.at(buttonIndex % translateLanguages.length).focus()
          break
        case 'Escape':
          event.preventDefault()
          setDropdownOpen(false)
          break
        case 'Tab':
          if(originalLanguage !== guessLanguage(document)){
            if(focusedLanguageIndexRef.current === (translateLanguages.length - 2) )
              setDropdownOpen(false)  
          }
          if(focusedLanguageIndexRef.current === (translateLanguages.length - 1) )
            setDropdownOpen(false)
          break
      }
    }

    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
      focusedLanguageIndexRef.current = 0;
    }
  }, [])

  const inner = (
    <Dropdown
      backgroundColor={controlPanelBackgroundColor}
      shadowColor={controlPanelShadowColor}
      translationButtonMarginBottom={translationButtonMarginBottom}
      prependButtonsInButtonContainer={prependButtonsInButtonContainer}
      extraStyles={extraCSSForTranslateButton}
      ref={ref}
    >
      <IconContainer>
        <TranslateIcon color={controlPanelForegroundColor} />
      </IconContainer>
      {originalLanguage !== guessLanguage(document) && (
        <LanguageButton
          foregroundColor={controlPanelForegroundColor}
          aria-label='See original'
          onClick={() => {
            if (onReset) {
              onReset()
            } else {
              translatorController.resetLanguage()
            }
          }}
        >
          {useFlagSeeOriginal ? 
        (<FlagSpan flagName={originalLanguage} />):
        ( "See" ) } Original
        </LanguageButton>
      )}
      {filteredLanguages().map((lang,i) => {
        return (
          <LanguageButton
            data-langCode={lang.code}
            data-langName={lang.name}
            aria-label={`${lang.name.split(" ")[1]}`}
            className="voiceintuitive-translate-button"
            key={lang.name}
            foregroundColor={controlPanelForegroundColor}
            onClick={async () => {
              if (onTranslate) {
                onTranslate(lang.code)
                return
              }
              await translatorController.translate(lang.code)
            }}
            onFocus={()=>{focusedLanguageIndexRef.current=i}}
          >
            <FlagSpan flagName={lang.code} />
            {" "+lang.name.split(" ")[1]}
          </LanguageButton>
        )
      })}
      <Disclaimer foregroundColor={controlPanelForegroundColor}>
        AI translation by
        <br />
        VoiceIntuitive™<br/><br/>
      </Disclaimer>
    </Dropdown>
  )
  if (!props.wrapWithShadow) { return inner }
  return createPortal(
      <StyleSheetManager target={translatorShadow}>
        {inner}
      </StyleSheetManager>,
      translatorShadow
  )
}
