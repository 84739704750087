import { Exclusions } from '../../../models'

export function isExcludedElement (child: HTMLElement, exclusions: Exclusions) {
  if (child.nodeType) {
    // console.log('child:', child);
    if (exclusions.id?.length > 0) {
      for (const item of exclusions.id) {
        if (child.id === item) {
          return true
        }
      }
    }
    if (exclusions.className?.length > 0) {
      for (const item of exclusions.className) {
        if (child.classList?.contains(item)) {
          return true
        }
      }
    }
    if (exclusions.tagName?.length > 0) {
      for (const item of exclusions.tagName) {
        if (child.tagName === item) {
          return true
        }
      }
    }
    if (exclusions.elementTextContent?.length > 0) {
      for (const item of exclusions.elementTextContent) {
        //if and when we are handling span elements, the real textContent might be different to what's copypasted from page
        if (child.textContent?.replace(/ +/g," ") === item) {
          return true
        }
      }
    }
    return false
  } else {
    return false
  }
}
