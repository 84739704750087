import styled from 'styled-components'
import { h } from 'preact'

export const Svg = styled.svg`
  position: relative;
  top: 4.5px;
  height: 20px;
`
interface Props {
  color: string
}

export function PlayIcon ({ color }: Props) {
  return <Svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
      >
          <path
              d="M17.4509 11.1568L9.59375 6.14253C8.88839 5.73015 8 6.25969 8 7.12664V16.874C8 17.7362 8.88393 18.2705 9.59375 17.8581L17.4509 13.125C18.183 12.6985 18.183 11.5879 17.4509 11.1568Z"
              fill={color}
          />
          <circle
              cx="12"
              cy="12"
              r="11.375"
              stroke={color}
              strokeWidth="1.25"
          />

  </Svg>
}
