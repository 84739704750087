export function mountIframe (
  src: string,
  playIframe: (iframe: HTMLIFrameElement) => void
) {
  // remove any pre-existing iframes
  const existingIframe = window.document.getElementById(
    'voice-intuitive-playlist-iframe'
  )
  if (existingIframe) {
    existingIframe.remove()
  }

  const body = window.document.body
  const iframe = window.document.createElement('iframe')
  iframe.id = 'voice-intuitive-playlist-iframe'
  iframe.style.display = 'none'
  iframe.onload = () => playIframe(iframe)
  iframe.setAttribute('src', src)

  body.appendChild(iframe)
  return iframe
}
