/*
globalTranslations are translate rules for words from all languages, translations for the ones with the lang string parameter.
same with process.
*/

export interface CustomTranslationsData {
  lockedWords?: string[]
  globalPreprocess?: Record<string, string>
  globalTranslations?: Record<string, string>
  translations?: Record<string, Record<string, string>>
  globalPostprocess?: Record<string, string>
  postprocess?: Record<string, Record<string, string>>
}

export class CustomTranslations {
  constructor (
    readonly lockedWords: string[] = [],
    readonly globalPreprocess: Record<string, string> = {},
    readonly globalTranslations: Record<string, string> = {},
    readonly translations: Record<string, Record<string, string>> = {},
    readonly globalPostprocess: Record<string, string> = {},
    readonly postprocess: Record<string, Record<string, string>> = {}
  ) {}

  toCustomTranslator (lang: string) {
    const ret: Record<string, string> = {}
    this.lockedWords.forEach(w => { ret[w.toLowerCase()] = w })
    Object.entries(this.globalTranslations).forEach(([k, v]) => { ret[k.toLowerCase()] = v })
    Object.entries(this.translations[lang] || {}).forEach(([k, v]) => { ret[prepareWord(k)] = v })
    const postprocess = { ...this.globalPostprocess, ...this.postprocess[lang] }
    const postprocessCaseFix = Object.fromEntries(Object.entries(postprocess).map(([k, v]) => [prepareWord(k), v]))
    return new CustomTranslator(ret, postprocessCaseFix,this.globalPreprocess)
  }
}

export class CustomTranslator {
  constructor (
    private readonly translations: Record<string, string>,
    private readonly postprocess: Record<string, string>,
    private readonly preprocess: Record<string, string>
    ) {}

  private readonly postprocessPhrases = Object.entries(this.postprocess).map(arr => {
    if (arr[0].includes(' ')) {
      return {
        firstWord: arr[0].split(' ')[0].toLowerCase(),
        matchString: arr[0].toLowerCase(),
        wordAmt: arr[0].split(' ').length,
        replacement: arr[1]
      }
    }
    return undefined;
  }).filter(obj => obj !== undefined)

  getCustomWordTranslation (original: string): string | null {
    const { word, punctuation } = separatePunctuation(original)

    const translation = this.translations[prepareWord(word)]
    const result = fixCase(word, translation)
    if(word.match(/\S@\S/) || word.match(/\S\(a\)\S/))
      return `<span class="notranslate" style="font-family: inherit;font-weight: inherit;color:inherit;">${original}</span>` 
    if (!result) { return null }
    return `<span class="notranslate" style="font-family: inherit;font-weight: inherit;color:inherit;">${result + punctuation}</span>`
  }

  getPostprocessedWord (original: string):string[]|null {
    const { word, punctuation } = separatePunctuation(original)
    const ret = fixCase(word, this.postprocess[prepareWord(word)])
    if (!ret) { return null }
    return [ret + punctuation]
  }

  getPostprocessedPhrase (arr: string[]):{replacedLength:number,replacement:string[]}|null {
    if (this.postprocessPhrases.length === 0) return null
    if (arr[0] === '') return null

    let result = null
    const word = arr[0].toLowerCase()

    this.postprocessPhrases.forEach(phrase => {
      if (phrase?.firstWord === word) {
        const {word:original,punctuation} = separatePunctuation(arr.slice(0, phrase.wordAmt).join(' '))
        if (original.toLowerCase() === phrase.matchString) {
          const replacement = (fixCase(original, phrase?.replacement)+punctuation)?.split(' ')
          result = { replacedLength: original.split(' ').length, replacement:replacement }
        }
      }
    })

    return result
  }

  doCustomTranslations (paragraph: string) {
    paragraph = this.addNbsps(paragraph)
    const ret = paragraph.split(' ').map(w => this.getCustomWordTranslation(w.trim()) ?? w).join(' ')
    return ret
  }

  doPostprocess (paragraph: string) {
    let result: string[] = []
    let arr = paragraph.split(' ')
    let match

    while (arr.length > 0) {
      match = this.getPostprocessedPhrase(arr)
      if (match) {
        arr = arr.slice(match.replacedLength)
        result = [...result, ...match.replacement]
        continue
      }
      result = [...result, ...this.getPostprocessedWord(arr[0]) ?? [arr[0]]]
      arr = arr.slice(1)
    }

    return result.join(' ')
  }

  doPreprocess (paragraph: string) {
    
    const handledParagraph = this.addNbsps(paragraph)
    const words = handledParagraph.split(' ')
    
    return words
      .map((original:string)=>{
        const { word, punctuation } = separatePunctuation(original)
        const ret = fixCase(word, this.preprocess[word])
        if (!ret) { return original }
        return ret.concat(punctuation)
      })
      .join(' ')
  
  }

  private addNbsps (paragraph: string) {
    const keysWithSpaces = Object.keys(this.translations).filter(k => k.includes(' '))
    for (const key of keysWithSpaces) {
      paragraph = injectNbsps(key, paragraph)
    }
    return paragraph
  }
}

function fixCase (original: string, output?: string) {
  if (!output) { return null }
  if (original.toLowerCase() === output.toLowerCase()) { return original }
  if (original === original.toUpperCase()) return output.toUpperCase()
  if (original[0] === original[0].toUpperCase() && output === output.toLowerCase()) {
    return output[0].toUpperCase() + output.slice(1)
  } else {
    return output
  }
}
const nbsp = '\u00A0'
/**
 * lower case & nbsp removed
 * @param word
 */
function prepareWord (word: string) {
  return word.toLowerCase().trim().replace(new RegExp(nbsp, 'g'), ' ')
}
/**
 * warning, might be heavy function, uses big regex
 * @param original
 *
 */
export function separatePunctuation (original: string) {
  const punctuation: string = /['!"#$%&\\'()\*+,\-\.\/:;<=>?@\[\\\]\^_`{|}~'\n]+$/.exec(original)?.join('') ?? ''
  const word = original.slice(0, original.length - punctuation.length)

  return { word, punctuation }
}

function injectNbsps (keyWithSpace: string, paragraph: string) {
  const exp = new RegExp(keyWithSpace, 'gi')
  return paragraph.replace(exp, str => str.replace(/ /g, nbsp))
}
