import { h, render } from 'preact'
import { App } from './App'
import loader from './loader'
import { Configurations } from './models'

/**
 * Default configurations that are overridden by
 * parameters in embedded script.
 */

const defaultConfig: Configurations = {
  debug: false,
  useDevAPI: false,
  productionAPIUrl:"https://api-stage.voiceintuitive.com/",
  readAreaSelector: '.voice-intuitive',
  smallSize:false,
  buttonContainerSelector: '#voice-intuitive-root',
  prependButtonsInButtonContainer:false,
  extraCSSForListenButton:"",
  extraCSSForTranslateButton:"",
  extraCSSForTranslateDropdown:"",
  buttonForegroundColor: '#FFFFFF',
  buttonBackgroundColor: '#000000',
  disabledPaths: [],
  buttonMargin: '0 0 14px',
  controlPanelEnabled: true,
  controlPanelShadowColor: '#a9a9a9',
  controlPanelBackgroundColor: '#ffffff',
  controlPanelForegroundColor: '#333333',
  textHighlightingEnabled: false,
  textHighlightDelay: -0.1,
  textHighlightPrimaryColor: '#00000016',
  textHighlightSecondaryColor: '',
  shareButtonEnabled: true,
  useLocalStorageForSpeedSelector: false,
  speechCustomizationWordsToSpellOut:["ppm","PPM"],
  excludeText: {
    id: [],
    className: ['voice-no-read'],
    tagName: ['STYLE', 'SCRIPT', 'NOSCRIPT', 'IMG', 'IMAGE', 'IFRAME'],
    elementTextContent:[],
    singleWords:[]
  },
  globalExcludeText:{
    id: [],
    className: ['gform_validation_container'],
    tagName: [],
    elementTextContent:[],
    singleWords:[]
  },
  translationEnabled: false,
  translationAreaSelector: '.voice-intuitive',
  translationButtonMarginBottom: '0px',
  useAlternativeTranslateButtonStyle:false,
  translationLanguageUrlParameterEnabled:false,
  useLocalStorageForTranslationLanguage: false,
  hidden: false,
  autoTranslationEnabled: false,
  translateWholePage:false,
  useFlagSeeOriginal:false,
  // Default phoneme list
  // Test every word you add to these list
  // incorrect ipa-punctuation will break things
  // first is normal word, second is how it should be pronounced
  phonemes:{
    "global":{
      "keskarin": "keskarin",
      "korona": "korona",
      "koronarokote": "koronarokote",
      "koronatartunta": "koronatartunta",
      "koronavirus": "koronavirus",
      "kesk": "kesk",
      "kesk.": "kesk",
      "(kesk)": "kesk",
      "(kesk.)": "kesk",
      "Tervashonka":"tervashoŋka",
      "Tervashongan":"tervashoŋŋan",
      "Tervashongat":"tervashoŋŋat",
      "Tervashonkien":"tervashoŋkien",
      "Tervashonkia":"tervashoŋkia",
      "vas.":"vas",
      "vas":"vas",
      "vastaisikin":"vastaisikin",
      "Vastaisikin":"vastaisikin",
      "lääkevasteeton":"læːkevvasteeton",
      "vasektomia":"vasektomia",
      "Vasektomia":"vasektomia",
    }, 
    "en":{
      "Oulu":"əwlʊ",
    },
    "fi":{
      "&gt":" suurempi kuin",
      "&lt": " pienempi kuin",
      "Ma":"ma",
      "ti":"ti",
      "ke":"ke",
      "to":"to",
      "pe":"pe"
    },
    "sv":{
      //WARNING: if you don't use correct IPA character instead of ä or ö, the TTS api might just time out for that call
      "&gt":" stœrre æn",
      "&lt":" mindre æn"
    }
  }
}

// main entry point - calls loader and render Preact app into supplied element
void loader(
  window,
  defaultConfig,
  window.document.currentScript,
  (el, config) => {
    return render(h(App, { ...config, element: el }), el)
  }
)
