import { useRef, useEffect } from 'preact/hooks'

// TODO: custom events can be added here if needed

/** Schedules method for later and returns cancellation method. */
export const useTimeout = (callback: () => void, delay: number) => {
  const savedCallback = useRef(callback)
  const cancel = useRef<NodeJS.Timeout | undefined>(undefined)
  const undo = () => cancel.current && clearTimeout(cancel.current)

  useEffect(() => {
    cancel.current = setTimeout(savedCallback.current, delay)
    return undo
  }, [delay])

  return undo
}

export const useHideOnClickOutside = (
  initialIsVisible: boolean,
  setComponentIsOpen: (isVisible: boolean) => void
) => {
  const ref = useRef(null)

  const handleClickOutside = (event: MouseEvent) => {
    // the timeout causes this function to fire after onClick is called on the button that opens the component
    // without the timeout, the component would be reopened immediately after closing
    setTimeout(() => {
      if (ref.current) {
        // @ts-expect-error
        if (ref.current && !ref.current.contains(event.target)) {
          setComponentIsOpen(false)
        }
      }
    }, 5)
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [])

  return { ref }
}

export const useIsFirstRender = (): boolean => {
  const isFirst = useRef(true)

  if (isFirst.current) {
    isFirst.current = false

    return true
  }

  return isFirst.current
}
