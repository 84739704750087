import styled from 'styled-components'

export const PlaylistButton = styled.button<{ foregroundColor: string }>`
  color: ${(props) => props.foregroundColor};
  display: block;
  width: 100%;
  cursor: pointer;
  background-color: transparent;
  border: none;
  text-align: left;
  border-bottom: #e5e5e5 solid 1px;
  min-width: 0;
  overflow: hidden;
  
  &:hover {
    opacity: 0.7;
  }
  position: relative;
`

export const PlaylistItemText = styled.p`
  display: inline-block;
  width: 100%;
  font-family: Quicksand;
  margin-left: 10px;
  font-size: 16px;
  text-overflow: ellipsis;
  min-width: 0;
`

export const Fader = styled.div<{ backgroundColor: string }>`
  position: absolute;
  background: linear-gradient(
    to right,
    ${(props) => props.backgroundColor}00 0%,
    ${(props) => props.backgroundColor} 70%,
    ${(props) => props.backgroundColor} 100%
  );
  height: 100%;
  width: 30px;
  top: 0;
  right: 0;
`
