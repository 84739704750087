
interface BaseProps {
  id?: string
  title: string
  url?: string
  ssml?: string
  html?: string
  plain?: string
  audioUrl?: string
  tts?: boolean
}
type ValidContent = { url: string } | { ssml: string } | { html: string } | { plain: string } | { tts: boolean, audioUrl: string }
export type PlaylistItemProps = BaseProps & ValidContent
export type PlaylistItemContentType = 'IFRAME' | 'SSML' | 'HTML' | 'PLAIN' | 'PLAIN_WITH_AUDIO' | 'HTML_WITH_AUDIO' | 'AUDIO'

interface BaseContent<T extends PlaylistItemContentType> {
  type: T
  url?: string
  ssml?: string
  html?: string
  plain?: string
  audioUrl?: string
  tts?: boolean
}

interface IframeContent extends BaseContent<'IFRAME'> {url: string}
interface SsmlContent extends BaseContent<'SSML'> {ssml: string}
interface HtmlContent extends BaseContent<'HTML'> {html: string}
interface PlainContent extends BaseContent<'PLAIN'> {plain: string}
interface PlainWithAudioContent extends BaseContent<'PLAIN_WITH_AUDIO'> {plain: string, audioUrl: string}
interface HtmlWithAudioContent extends BaseContent<'HTML_WITH_AUDIO'> {html: string, audioUrl: string}
interface AudioContent extends BaseContent<'AUDIO'> {audioUrl: string, tts: boolean}

export type PlaylistItemContent = IframeContent | SsmlContent | HtmlContent | PlainContent | PlainWithAudioContent | HtmlWithAudioContent | AudioContent

export class PlaylistItem {
  constructor (
    readonly id: string,
    readonly title: string,
    readonly content: PlaylistItemContent
  ) {}

  getType () {
    return this.content.type
  }
}

export function toPlaylistItem (props: PlaylistItemProps) {
  const type = resolveType(props)
  return new PlaylistItem(
    props.id ?? generateId(props),
    props.title,
    resolveContent(type, props)
  )
}

function resolveType (props: PlaylistItemProps): PlaylistItemContentType {
  if (props.audioUrl) {
    if (props.plain) {
      return 'PLAIN_WITH_AUDIO'
    } else if (props.html) {
      return 'HTML_WITH_AUDIO'
    } else {
      return 'AUDIO'
    }
  } else if (props.plain) {
    return 'PLAIN'
  } else if (props.html) {
    return 'HTML'
  } else if (props.ssml) {
    return 'SSML'
  } else if (props.url) {
    return 'IFRAME'
  } else {
    throw new Error('Illegal props')
  }
}

function resolveContent (type: PlaylistItemContentType, props: PlaylistItemProps): PlaylistItemContent {
  switch (type) {
    case 'IFRAME':
      return { type, url: props.url! }
    case 'SSML':
      return { type, ssml: props.ssml! }
    case 'HTML':
      return { type, html: props.html! }
    case 'PLAIN':
      return { type, plain: props.plain! }
    case 'PLAIN_WITH_AUDIO':
      return { type, plain: props.plain!, audioUrl: props.audioUrl! }
    case 'HTML_WITH_AUDIO':
      return { type, html: props.html!, audioUrl: props.audioUrl! }
    case 'AUDIO':
      return { type, tts: !!props.tts, audioUrl: props.audioUrl! }
  }
}
function generateId (props: PlaylistItemProps) {
  return String(hashCode(JSON.stringify(props)))
}
function hashCode (s: string) {
  return s.split('').reduce(function (a, b) { a = ((a << 5) - a) + b.charCodeAt(0); return a & a }, 0)
}
