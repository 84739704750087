import { FetchSpeechResponse } from './fetchSpeech'
import { decodeMultiStream } from '@msgpack/msgpack'
import { isMetaData, WordMetaData } from './WordMetaData'
const streamDebug = false
interface SpeechStreamReaderProps {
  onLoadProgress?: (loaded: number, total: number) => void
  onReceiveStreamUrl: (url: string) => void
  onReceiveFallbackUrl: (url: string, hasReceivedStreamUrl: boolean) => void
  onReceiveWordMetaData: (metaData: WordMetaData) => void
  debug?: boolean
}

export class SpeechStreamReader {
  private readonly props: SpeechStreamReaderProps
  constructor (props: SpeechStreamReaderProps) {
    this.props = props
  }

  public async read (response: FetchSpeechResponse) {
    const streamIterator = asyncIterableFromStream(response.body, this.props.onLoadProgress, response.contentLength)
    let hasReceivedStreamUrl = false
    for await (const item of decodeMultiStream(streamIterator)) {
      if (streamDebug) { console.log('received item from stream', item) }
      if (typeof item === 'string') {
        if (isFallbackUrl(item,this.props.debug)) {
          this.props.onReceiveFallbackUrl(item, hasReceivedStreamUrl)
        } else {
          this.props.onReceiveStreamUrl(item)
          hasReceivedStreamUrl = true
        }
        // just for benchmarking purposes
        if (this.props.debug) {
          console.timeLog(
            'Audio retrieval timer',
            isFallbackUrl(item,this.props.debug)
              ? 'audio file exists and was retrieved.'
              : 'a new audio file was generated and retrieved.'
          )
        }
      } else {
        // if the item is not a string it is or at least should be metadata for when a word is read
        if (isMetaData(item)) {
          this.props.onReceiveWordMetaData(item)
        }
      }
    }
  }
}
async function * asyncIterableFromStream (
  stream: ReadableStream<Uint8Array>,
  onLoadProgress: SpeechStreamReaderProps['onLoadProgress'] = () => undefined,
  total: number
) {
  const reader = stream.getReader()
  let loaded = 0
  try {
    while (true) {
      const { done, value } = await reader.read()
      if (done) {
        return
      }
      loaded += value.byteLength
      onLoadProgress(loaded, total)

      yield value
    }
  } finally {
    reader.releaseLock()
  }
}

function isFallbackUrl (item: string,debug:boolean=false) {
  const isFallbUrl = item.includes(".blob.core.windows.net")
  if(!isFallbUrl)
    console.log("warning, if Safari does have a plaing problem and following is false, it might be the reason:",isFallbUrl)
  if (debug){
    console.log(
      "isFallbackUrl?",
      isFallbUrl,
      // item.startsWith('https://vitest91ed.blob.core.windows.net') || 
      //   item.startsWith('https://vitelemetry.blob.core.windows.net'),
      item)
  }
  return isFallbUrl; 
  //return item.startsWith('https://vitest91ed.blob.core.windows.net') || item.startsWith('https://vitelemetry.blob.core.windows.net')
}
