import { useEffect, useState } from 'preact/hooks'
import { EventHandlers } from './EventHandlers'
import { usePlayerController, usePlaylistController } from '../context/PlayerContext'
import { useTranslatorController } from '../context/TranslatorContext'
import { useAppConfig } from '../context/ConfigContext'

export function useCreateEventListeners (element: HTMLElement | undefined) {
  // custom events go here
  // use window._vi('event', 'methodName') anywhere to trigger these functions
  const playerController = usePlayerController()
  const playlistController = usePlaylistController()
  const translatorController = useTranslatorController()
  const config = useAppConfig()
  const [eventHandlers] = useState(new EventHandlers(config, element, playerController, playlistController, translatorController))
  useEffect(() => {
    eventHandlers.setConfig(config)
    eventHandlers.setPlayerController(playerController)
    eventHandlers.setPlayListController(playlistController)
    eventHandlers.setTranslatorController(translatorController)
  }, [config, playerController, playlistController, translatorController])
  useEffect(() => {
    if (element)eventHandlers.createEventListeners(element)
  }, [element])
}
