// Must be the first import
//import "preact/debug";
import { h } from 'preact'
import { Configurations } from './models'
import { ReaderWidget } from './reader/components/ReaderWidget'
import { AppContext } from './context/AppContext'
import { createPortal, Suspense } from 'preact/compat'
import { ReaderButtons } from './reader/components/ReaderButtons'
import { StyleSheetManager } from 'styled-components'
import GlobalFonts from './assets/fonts'
import createShadowRoot from './services/createShadowRoot'
import { PlaylistWidget } from './reader/components/playlist/PlaylistWidget'
import Translator from './translator/components/Translator'
import { GoogleTranslateTab } from "./translator/components/GoogleTranslateTab";

type Props = Configurations

// this is currently causing issues and not yielding any benefits, so commenting it out
// translator is loaded conditionally
// const Translator = lazy(
//  async () => await import('./translator/components/Translator')
// )

export const App = ({ element, ...appSettings }: Props) => {
  // create shadow root for control panel
  const portalShadow = createShadowRoot('body', 'voice-intuitive-player')
  const playlistPortalShadow = createShadowRoot('body', 'voice-intuitive-playlist')
  const googleAttributionTabPortalShadow = createShadowRoot('body', 'voice-intuitive-attribution-tab',"999999999")

  return (
    <AppContext config={appSettings} element={element}>

      {/* inline play button */}
      <StyleSheetManager target={element}>
        <ReaderButtons />
      </StyleSheetManager>

      {/* player is created inside a portal */}
      {createPortal(
        <StyleSheetManager target={portalShadow}>
          <ReaderWidget />
        </StyleSheetManager>,
        portalShadow
      )}
    {createPortal(
        <StyleSheetManager target={playlistPortalShadow}>
            <PlaylistWidget />
        </StyleSheetManager>,
        playlistPortalShadow
    )}
    {createPortal(
        <StyleSheetManager target={googleAttributionTabPortalShadow}>
            <GoogleTranslateTab offsetY={0} position={"fixed"} />
        </StyleSheetManager>,
        googleAttributionTabPortalShadow
    )}

      {/* translator is loaded only if needed */}
      {appSettings.translationEnabled && (
        <Suspense fallback={<div>loading translator...</div>}>
            <Translator />
        </Suspense>
      )}

      <GlobalFonts />
    </AppContext>
  )
}