import { h } from 'preact'
interface Props {
  color: string
}
export function RemoveIcon ({ color }: Props) {
  return <path
        d="M0.16868 13.8298L6.27994 7.51664L0.16868 1.17277C-0.116167 0.803523 -0.0349825 0.372381 0.372341 0.126392C0.779665 -0.0899722 1.43124 -0.0275491 1.71749 0.310487L7.50261 6.2539L13.2478 0.310487C13.5733 -0.0280781 14.1843 -0.0899721 14.5503 0.125863C15.0395 0.372381 15.1214 0.802994 14.8358 1.17277L8.68329 7.51664L14.8358 13.8298C15.1214 14.1689 15.0395 14.5995 14.5503 14.8461C14.4285 14.9386 14.2249 15 14.0625 15C13.7356 15 13.4102 14.9074 13.2478 14.6921L7.50261 8.74871L1.71749 14.6921C1.55512 14.9074 1.22828 15 0.944134 15C0.780365 15 0.576703 14.9386 0.373041 14.8461C-0.0349825 14.5995 -0.116167 14.1689 0.16868 13.8298Z"
        fill={color}
    />
}
