import { separatePunctuation } from '../../../translator/translatorServices/CustomTranslations'

export type PhoneticWords = Record<string, Record<string, string>>

export function addPhonemes (original: string, lang:string, phoneticWords: PhoneticWords) {
  const { word,punctuation } = separatePunctuation(original)
  let phoneticResult = ""
  if(phoneticWords[lang] && phoneticWords[lang][word])
    phoneticResult = phoneticWords[lang][word];
  else if(phoneticWords["global"][word])
    phoneticResult = phoneticWords["global"][word];
  
  return phoneticResult ? `<phoneme alphabet="ipa" ph="${phoneticResult}">${original}</phoneme>${punctuation}' ` : original
}
