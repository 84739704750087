export interface WordMetaData {
  audiooffset: number
  textoffset: number
  wordlength: number
}

// type guard for WordMetaData
export const isMetaData = (obj: unknown): obj is WordMetaData => {
  return (
    typeof obj === 'object' &&
        obj !== null &&
        'audiooffset' in obj &&
        'textoffset' &&
        'wordlength' in obj
  )
}
