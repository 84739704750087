// Helper function to check if something is a plain object
const isObject = (item:any) => {
    return (item && typeof item === 'object' && !Array.isArray(item));
  };
  
  // Helper function to check if something is an array
  const isArray = (item:any[]) => {
    return Array.isArray(item);
  };
  
  // Helper function to create a deep clone of an item
  const deepClone:any = (item:any) => {
    if (isArray(item)) {
      return item.map((i:any) => deepClone(i));
    }
    if (isObject(item)) {
      return Object.fromEntries(
        Object.entries(item).map(([key, value]) => [key, deepClone(value)])
      );
    }
    return item;
  };
  

  // Immutable deep merge that handles arrays
  export function mergeDeepImmutable(target:any, ...sources:any) {
    if (!sources.length) return deepClone(target);
    const source = sources.shift();
  
    if (isObject(target) && isObject(source)) {
      const result = deepClone(target);
      for (const key in source) {
        if (isObject(source[key])) {
          result[key] = target[key] ? 
            mergeDeepImmutable(target[key], source[key]) : 
            deepClone(source[key]);
        } else if (isArray(source[key])) {
          // Handle arrays by creating a new concatenated array
          result[key] = [...(target[key] || []), ...source[key]];
        } else {
          result[key] = source[key];
        }
      }
      return result;
    } else if (isArray(target) && isArray(source)) {
      // Create a new concatenated array
      return [...target, ...source];
    }
  
    return deepClone(source || target);
  }