import { Fragment, h } from 'preact'
import styled from 'styled-components'
import { useAppConfig } from '../../context/ConfigContext';
import { useTranslatorController } from '../../context/TranslatorContext';
import { GoogleTranslateIcon } from './GoogleTranslateIcon';


interface TabProps {
  color?: string,
  backgroundColor?:string;
  offsetY?:number;
  offsetX?:number;
  position?:string;
  zIndex?:string
}
interface SimpleTabProps {
  position:string;
  backgroundColor:string;
  offsetY:number;
  offsetX:number;
  configOffsetX?:number;
  zIndex:string;
}

const Tab = styled.div<SimpleTabProps>`
position: ${props=>props.position};
bottom: ${props=>props.offsetY}px;
right: ${props=>props.configOffsetX ? props.configOffsetX:props.offsetX}px;
background-color:${props=>props.backgroundColor};
z-index: ${props=>props.zIndex};
border-radius: 14px 0px 0px 0px;
padding: 2px 7px 0px 7px;
`

export const GoogleTranslateTab =({
    color="#757575",
    backgroundColor="#e6e6e6",
    offsetY=70,
    offsetX=0,
    position="fixed",
    zIndex="inherit"
}: TabProps) =>{
    const config = useAppConfig();
    const translatorController = useTranslatorController();
    const displayAttributionTab = config.showGoogleAttributionTab ? translatorController.isTranslated:false;
    const configOffsetX = config.googleAttributionTabOffsetX;
    
   
 
  return (
    displayAttributionTab ?  
      <Tab 
        position={position}
        offsetY={offsetY}  
        configOffsetX={configOffsetX} 
        offsetX={offsetX} 
        backgroundColor={backgroundColor} 
        zIndex={zIndex} >
          <GoogleTranslateIcon color={color} />
      </Tab>:<Fragment></Fragment>
  )
}
