import { Configurations, Content } from '../models'
import wrapTextNodesToSpans from './wrapTextNodesToSpans'

class DomManipulator {
  private originalContent: Content[] = []
  originalLanguage?: string
  public hijack (element: HTMLElement | null,config?:Configurations) {
    if (element == null) {
      throw new Error('no element selected')
    }
    if(element.querySelector(`[data-voice-intuitive-sentence`)){
      console.log('already mounted')
      return this.originalContent
    }
    this.originalLanguage = document.documentElement.lang
    this.originalContent = this.originalContent.concat(wrapTextNodesToSpans(element))
    return this.originalContent
  }

  public restore () {
    this.originalContent.forEach(c => this.restoreElement(c))
    this.originalLanguage = undefined
    this.originalContent = []
  }

  private restoreElement (originalContent: Content) {
    const spanNode = this.findSpan(originalContent.id)
    if (!spanNode) {
      console.warn('element not found on page anymore', originalContent)
      return
    }
    if (!spanNode.parentNode) {
      console.warn('span element does not have a parent', originalContent, spanNode)
      return
    }
    if(spanNode.tagName.toLowerCase()==="input"){
      (<HTMLInputElement>spanNode).placeholder=originalContent.content;
      (<HTMLElement>spanNode).removeAttribute(`data-voice-intuitive-sentence`);
    }
    else{
      spanNode.parentNode.replaceChild(document.createTextNode(originalContent.content), spanNode)
    }
  }

  private findSpan (id: string) {
    return document.querySelector(`[data-voice-intuitive-sentence='${id}']`)
  }

  public getSpans ():HTMLElement[] {
    return Array.from(document.querySelectorAll(`[data-voice-intuitive-sentence`));
  }

  public setTranslations (translations: Record<string, string>) {
    Object.keys(translations).forEach(k => this.setTranslation(k, translations[k]))
  }

  private setTranslation (id: string, translation: string) {
    const spanNode = this.findSpan(id)
    if (!spanNode) {
      console.warn('element not found on page anymore', id)
      return
    }
    if(spanNode.tagName.toLowerCase()==="input"){
      (<HTMLInputElement>spanNode).placeholder=translation;
    }
    else{
      spanNode.innerHTML = translation
    }
  }

  public reset () {
    this.originalContent = []
  }

  public isActive () {
    return this.originalContent.length > 0
  }
}
export const domManipulator = new DomManipulator()

export function useDomManipulator () {
  return {
    hijack (element: HTMLElement) {
      domManipulator.hijack(element)
    },
    restore () {
      domManipulator.restore()
    }
  }
}
