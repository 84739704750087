// checks the page language, returns either en, sv or fi(default)
import { findSupportedLanguage, LocalizationMode } from '../localizations'
import { domManipulator } from './domManipulator'

const guessLanguage = (rootDocument: HTMLDocument = document, localizationMode: LocalizationMode = 'selected_or_page') => {
  switch (localizationMode) {
    case 'selected_or_browser':
      return domManipulator.originalLanguage ? parseLanguageFromLocale(rootDocument.documentElement.lang) : findSupportedLanguage()
    case 'selected_or_page':
      return parseLanguageFromLocale(rootDocument.documentElement.lang)
    case 'browser':
      return findSupportedLanguage()
    case 'page':
      return parseLanguageFromLocale(domManipulator.originalLanguage ?? rootDocument.documentElement.lang)
  }
  if (rootDocument.documentElement.lang) {
    // lang can have multiple variations such as en-us, only the first part is interesting
    return parseLanguageFromLocale(document.documentElement.lang)
  } else {
    return 'en'
  }
}
export function parseLanguageFromLocale (locale?: string) {
  return locale?.split('-')[0] ?? 'en'
}

export default guessLanguage
