import { h } from 'preact'
import styled from 'styled-components'
import { CrossSymbol } from '../symbols/CrossSymbol'

interface Props {
  onRemove: () => Promise<void>
  color: string
}

export function PlaylistRemoveButton ({ onRemove, color }: Props) {
  return <Wrapper onClick={e => {
    e.stopPropagation()
    void onRemove()
  }}>
        <svg
             width="12"
             height="12"
             viewBox="0 0 12 12"
        >
            <CrossSymbol />
        </svg>
    </Wrapper>
}
const Wrapper = styled.button`
  position: relative;
  top: 3px;
  cursor: pointer;
  background-color: transparent;
  border: none;
  padding: 5px;
  &:hover {
    opacity: 0.7;
  }
`
