import { PlaylistItem } from '../../readerServices/playlist/PlaylistItem'
import styled from 'styled-components'
import { h } from 'preact'
import { usePlayerController } from '../../../context/PlayerContext'
import { PrevButton } from '../buttons/PrevButton'
import { NextButton } from '../buttons/NextButton'
import { SlimSeekBar } from './SlimSeekBar'

interface Props {
  item?: PlaylistItem
  progress: number
}

export function CurrentItemIndicator ({ item, progress }: Props) {
  const playerController = usePlayerController()
  if (!item) { return null }
  const progressString = timeToString(playerController.getTime())
  const totalString = timeToString(playerController.getLength())
  const timeLeft = timeToString(playerController.getLength()-playerController.getTime())
  const displayTime:Boolean = 
    !Number.isNaN(playerController.getLength()) && !Number.isNaN(playerController.getTime()) && timeLeft!=="0:00"
  return <Wrapper>
          <PrevButton size={14}/>
          <WrapperInner title={progressString + '/' + totalString}>
            <Title >{ item.title.slice(0,9)}...</Title>
          </WrapperInner>
          <NextButton size={14}/>
          <SlimSeekBar progress={playerController.getProgress()}/>
          <PlayTimeDiv>&nbsp;{displayTime ? "-"+timeLeft:""}</PlayTimeDiv>
        </Wrapper>
}


const Wrapper = styled.div`
  position: absolute;
  bottom: 0;
  height: 30px;
  width:100%;
  max-width: 400px;
  text-align: center;
  vertical-align: middle;
  
  display:flex;
  align-items: center;
  justify-content: center;

  left:50%;
  translate:-50% 0;
  
`
const WrapperInner = styled.div`
  margin-left:12px;
  margin-right:12px;  
  position: relative;
  // flex-grow: 1;  
  // flex-shrink: 1;
  box-sizing: border-box;
  min-width: 0;
`
const Title = styled.div`
  vertical-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-family: Quicksand;
  font-size: 12px;
  line-height:16px; 
`
const PlayTimeDiv = styled.div`
  font-family: Quicksand;
  font-size: 12px;
  line-height:16px;
  width:35px;
`

export function timeToString (time: number) {
  return `${Math.floor(time / 60)}:${zeroPad(Math.floor(time % 60))}`
}

function zeroPad (number: number) {
  if (number < 10) { return `0${number}` } else { return `${number}` }
}
