import { h } from 'preact'
import styled, { keyframes } from 'styled-components'
import { usePlayerController } from '../../../context/PlayerContext'
import { useAppConfig } from '../../../context/ConfigContext'

const rotate = keyframes`
 0% { transform: rotate(0deg); }
 100% { transform: rotate(360deg); }
`

const RotatingGroup = styled.g`
  transform-origin: 44.8px 44px;
  animation: ${rotate} 1.2s linear infinite;
`

export function ControlPanelPlayGlyph () {
  const { status } = usePlayerController()
  const { controlPanelForegroundColor } = useAppConfig()

  switch (status) {
    case 'playing':
      return (
        <g>
          <rect
            x="36"
            y="31"
            width="4"
            height="25"
            rx="2"
            fill={controlPanelForegroundColor}
          />
          <rect
            x="48"
            y="31"
            width="4"
            height="25"
            rx="2"
            fill={controlPanelForegroundColor}
          />
        </g>
      )
    case 'uninitialized':
    case 'paused':
      return (
        <path
          d="M39.8205 53.2454L55.9612 44.8843C56.6272 44.5298 56.9532 44.2072 56.9334 43.7008C56.9177 43.2462 56.49 42.9338 55.9612 42.6595L39.8205 34.2969C39.5925 34.1816 38.9494 33.855 38.4964 33.8733C38.188 33.8733 37.2037 33.8712 37.2037 35.8862V50.6082L33.5303 52.4419C33.5044 52.1778 33.4927 51.9167 33.4927 51.6546V35.8862C33.4927 30.5706 37.0792 30.1582 38.1764 30.1582C39.8561 30.1582 41.3637 30.9145 41.5308 31.0014L57.6674 39.3625C59.9023 40.5206 61.0365 41.9703 61.0365 43.6688C61.0365 46.2994 58.2302 47.8887 57.6674 48.1813L41.5298 56.5425C40.3163 57.1713 39.2303 57.4781 38.2114 57.4781C36.6449 57.4771 35.5549 56.8538 34.7798 55.812L39.8205 53.2454Z"
          fill={controlPanelForegroundColor}
        />
      )
    case 'disabled':
      return (
        <path
          d="M39.8205 53.2454L55.9612 44.8843C56.6272 44.5298 56.9532 44.2072 56.9334 43.7008C56.9177 43.2462 56.49 42.9338 55.9612 42.6595L39.8205 34.2969C39.5925 34.1816 38.9494 33.855 38.4964 33.8733C38.188 33.8733 37.2037 33.8712 37.2037 35.8862V50.6082L33.5303 52.4419C33.5044 52.1778 33.4927 51.9167 33.4927 51.6546V35.8862C33.4927 30.5706 37.0792 30.1582 38.1764 30.1582C39.8561 30.1582 41.3637 30.9145 41.5308 31.0014L57.6674 39.3625C59.9023 40.5206 61.0365 41.9703 61.0365 43.6688C61.0365 46.2994 58.2302 47.8887 57.6674 48.1813L41.5298 56.5425C40.3163 57.1713 39.2303 57.4781 38.2114 57.4781C36.6449 57.4771 35.5549 56.8538 34.7798 55.812L39.8205 53.2454Z"
          fill={controlPanelForegroundColor}
        />
      )
    case 'loading':
      return (
        <RotatingGroup>
          <path
            d="M44.3618 63.997L44.7258 64C47.8048 64 50.7208 63.304 53.3268 62.061C54.3228 61.586 54.7458 60.391 54.2708 59.395C53.7958 58.398 52.6008 57.975 51.6048 58.451C49.5858 59.413 47.3338 59.965 44.9558 59.998L44.7258 60C43.1688 60 41.6638 59.778 40.2398 59.363C34.4598 57.679 30.0318 52.821 28.9698 46.802C28.7778 45.715 27.7398 44.988 26.6528 45.18C25.5658 45.371 24.8388 46.41 25.0308 47.497C26.3378 54.905 31.7228 60.907 38.7838 63.102L39.1208 63.203C40.7878 63.689 42.5458 63.964 44.3618 63.997ZM59.2808 37.345C60.2088 39.372 60.7258 41.625 60.7258 44C60.7258 47.823 59.3848 51.333 57.1478 54.085C56.4518 54.942 56.5818 56.202 57.4378 56.899C58.2948 57.595 59.5548 57.465 60.2518 56.609C63.0488 53.168 64.7258 48.78 64.7258 44C64.7258 41.031 64.0778 38.213 62.9178 35.679C61.6728 32.961 59.8358 30.57 57.5728 28.671C56.7268 27.962 55.4648 28.072 54.7548 28.918C54.0458 29.764 54.1558 31.026 55.0018 31.736C56.8138 33.256 58.2838 35.169 59.2808 37.345ZM29.5968 30.918C27.6768 33.137 26.2378 35.782 25.4378 38.693C25.1458 39.757 25.7728 40.859 26.8368 41.151C27.9018 41.443 29.0028 40.816 29.2958 39.752C29.9348 37.425 31.0868 35.31 32.6218 33.536C35.5558 30.145 39.8908 28 44.7258 28C46.1098 28 47.4538 28.175 48.7348 28.506C49.8038 28.782 50.8958 28.138 51.1708 27.069C51.4468 26 50.8028 24.908 49.7338 24.633C48.1338 24.22 46.4548 24 44.7258 24C38.6818 24 33.2648 26.681 29.5968 30.918Z"
            fill={controlPanelForegroundColor}
          />
        </RotatingGroup>
      )
    case 'error':
      return (
        <path
          d="M44.432 51.392C43.344 51.392 42.752 50.784 42.656 49.568L41.264 31.904C41.2 30.912 41.456 30.08 42.032 29.408C42.64 28.736 43.424 28.4 44.384 28.4C45.344 28.4 46.128 28.736 46.736 29.408C47.344 30.08 47.6 30.912 47.504 31.904L46.16 49.568C46.064 50.784 45.488 51.392 44.432 51.392ZM44.096 62C43.168 62 42.448 61.76 41.936 61.28C41.456 60.768 41.216 60.048 41.216 59.12V58.304C41.216 57.376 41.456 56.672 41.936 56.192C42.448 55.68 43.168 55.424 44.096 55.424H44.72C45.648 55.424 46.352 55.68 46.832 56.192C47.344 56.672 47.6 57.376 47.6 58.304V59.12C47.6 60.048 47.344 60.768 46.832 61.28C46.352 61.76 45.648 62 44.72 62H44.096Z"
          fill={controlPanelForegroundColor}
        />
      )
  }
}
