import { h } from 'preact'
import styled from 'styled-components'
import { useAppConfig } from '../../../context/ConfigContext'

const Svg = styled.svg`
  &:hover > path,
  &:hover > rect {
    opacity: 0.7;
  }
`

export function ShareSymbol () {
  const { controlPanelForegroundColor } = useAppConfig()

  return (
    <Svg
      width="23"
      height="28"
      viewBox="0 0 23 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.09996 9.90002H0.899963V24.7273C0.899963 26.0465 2.07321 27.1 3.49996 27.1H19.5C20.9267 27.1 22.1 26.0465 22.1 24.7273V9.90002H20.9V24.7273C20.9 25.4164 20.2823 25.9909 19.5 25.9909H3.49996C2.71758 25.9909 2.09996 25.4164 2.09996 24.7273V9.90002Z"
        fill={controlPanelForegroundColor}
      />
      <path
        d="M11.2577 1.07576L8.0757 4.25774C7.84138 4.49205 7.84138 4.87195 8.0757 5.10627C8.31001 5.34058 8.68991 5.34058 8.92423 5.10627L11.0819 2.94855V17.3C11.0819 17.6314 11.3506 17.9 11.6819 17.9C12.0133 17.9 12.2819 17.6314 12.2819 17.3V2.94855L14.4397 5.10627C14.674 5.34058 15.0539 5.34058 15.2882 5.10627C15.5225 4.87195 15.5225 4.49205 15.2882 4.25774L12.1062 1.07576C11.8719 0.841446 11.492 0.841446 11.2577 1.07576Z"
        fill={controlPanelForegroundColor}
      />
    </Svg>
  )
}
