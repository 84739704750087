import { h } from 'preact'
import styled from 'styled-components'
import { useLocalizations } from '../../../localizations'
import { usePlayerController } from '../../../context/PlayerContext'
import { RewindSymbol } from '../symbols/RewindSymbol'

const Button = styled.button`
  cursor: pointer;
  background-color: transparent;
  padding: 0;
  border: none;
`
interface Props {
  size?: number
}
export function GoBackButton ({ size }: Props) {
  const playerController = usePlayerController()
  const localizations = useLocalizations()

  return (
    <Button
      aria-label={localizations.backButton}
      onClick={() => playerController.skip(-15)}
    >
      <RewindSymbol size={size} />
    </Button>
  )
}
