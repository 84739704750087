import { ExtractedSentence } from './extractSsmlAndInjectOffsets'

export const BREAK_MEDIUM = '__BREAK_MEDIUM__'
export const BREAK_SENTENCE_MEDIUM = { words: [BREAK_MEDIUM], break: true }

export function removeExtraBreaksFromWords (words: string[]) {
  return removeHangingBreakWords(removeConcurrentBreakWords(words.filter(Boolean)))
}
function removeHangingBreakWords (words: string[]) {
  if (isBreakWord(words[0])) { words = words.slice(1) }
  if (isBreakWord(words[words.length - 1])) { words = words.slice(0, words.length - 1) }
  return words
}

function removeConcurrentBreakWords (words: string[]) {
  return words.reduce<string[]>((acc, cur) => {
    if (isBreakWord(cur) && isBreakWord(acc[acc.length - 1])) {
      return acc
    }
    if (!cur.trim()) { return acc }
    return [...acc, cur]
  }, [])
}

function isBreakWord (word: string = '') {
  return word.startsWith('__BREAK_')
}

export function removeExtraBreaksFromSentences (sentences: ExtractedSentence[]) {
  return removeHangingBreakSentences(removeConcurrentBreakSentences(sentences))
}
function removeHangingBreakSentences (sentences: ExtractedSentence[]) {
  if (sentences[0]?.break) { sentences = sentences.slice(1) }
  if (sentences[sentences.length - 1]?.break) { sentences = sentences.slice(0, sentences.length - 1) }
  return sentences
}

function removeConcurrentBreakSentences (sentences: ExtractedSentence[]) {
  return sentences.reduce<ExtractedSentence[]>((acc, cur) => {
    if (cur.break && acc[acc.length - 1]?.break) {
      return acc
    }
    if (!cur.words.length) { return acc }
    return [...acc, cur]
  }, [])
}
export function getTrailingBreak (element: HTMLElement) {
  if (element.nodeType === 3 || element.nodeType === 8) { return null }
  if (element.tagName === 'TIME') { return BREAK_MEDIUM }
  if (element.tagName === 'IMG') { return BREAK_MEDIUM }
  if (element.tagName === 'INPUT') { return BREAK_MEDIUM }
  if (element.tagName === 'span') { return null }

  const display = getComputedStyle(element, null).display
  switch (display?.toLowerCase()) {
    case 'block':
      return BREAK_MEDIUM
    case 'flex':
      return BREAK_MEDIUM
    case 'list-item':
      return BREAK_MEDIUM
    case 'grid':
      return BREAK_MEDIUM
    case 'table':
      return BREAK_MEDIUM
    case 'table-row':
      return BREAK_MEDIUM
    default:
      return null
  }
}

export function toBreak (word: string) {
  switch (word) {
    case BREAK_MEDIUM:
      return '<break strength="medium"/>'
    default:
      return null
  }
}
