import { ComponentChildren, createContext, h } from 'preact'
import { PlayerController } from '../reader/readerServices/audio/PlayerController'
import { Status } from '../models'
import { PlayListController } from '../reader/readerServices/playlist/PlayListController'
import emptyAudioFile from '../assets/emptyAudioFile'
import { useContext, useEffect, useRef, useState } from 'preact/hooks'
import { useAppConfig } from './ConfigContext'
import { usePlaylist } from '../reader/readerServices/playlist/Playlist'
import { usePersistentFloatState } from '../hooks/usePersistentState'

export interface PlayerContext {
  playerController: PlayerController
  playlistController: PlayListController
}
const Ctx = createContext<PlayerContext>(
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  {} as PlayerContext
)
interface Props {
  children: ComponentChildren
}

export function PlayerContextProvider ({ children }: Props) {
  const config = useAppConfig()
  const audioPlayer = useRef<HTMLAudioElement>(null)

  const [total, setTotal] = useState(0)
  const [elapsed, setElapsed] = useState(0)
  const [status, setStatus] = useState<Status>('uninitialized')
  const [playbackSpeed, setPlaybackSpeed] = usePersistentFloatState('playbackSpeed', 1.0)
  const { playlist, updatePlaylist } = usePlaylist()

  const [widgetOpen, setWidgetOpen] = useState(shouldBeOpenOnFirstLoad())

  const [playlistWidgetOpen, setPlaylistWidgetOpen] = useState(false)
  const [speedSelectorOpen, setSpeedSelectorOpen] = useState(false)
  const [initialized, setInitialized] = useState(false)
  const [_trigger, setRefreshTrigger] = useState(0)


  const playerController = new PlayerController({
    config,
    status,
    setStatus,
    playbackSpeed,
    setPlaybackSpeed,
    widgetOpen,
    setWidgetOpen,
    elapsed,
    total,
    audioPlayer,
    speedSelectorOpen,
    setSpeedSelectorOpen,
    setRefreshTrigger

  })
  const playlistController = new PlayListController({
    config,
    playerController,
    playlist,
    updatePlaylist,
    playlistWidgetOpen,
    setPlaylistWidgetOpen,
    initialized,
    setInitialized

  })

  useEffect(() => playlistController.openIfNonEmpty(), [])

  return <Ctx.Provider value={{ playerController, playlistController }}>
      <audio
          ref={audioPlayer}
          src={emptyAudioFile(config.useFileAsEmptySoundbite ? true:false)}
          onTimeUpdate={(e) => {
            playerController.onTimeUpdate()
            setElapsed(e.timeStamp)
            setTotal(e.currentTarget.duration)
          }}
          onLoadedMetadata={async (e) =>
            await playerController.onMetaDataLoaded()
          }
          onEnded={async (e) => { await playlistController.playNext() }}
      />
      {children}
  </Ctx.Provider>
}

function shouldBeOpenOnFirstLoad () {
  const urlParams = new URLSearchParams(window.location.search)
  return !!urlParams.get('voice-intuitive-open')
}

export function usePlayerController () {
  const { playerController } = useContext(Ctx)
  return playerController
}
export function usePlaylistController () {
  const { playlistController } = useContext(Ctx)
  return playlistController
}
