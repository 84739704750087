interface ElementAndRange {
  element: HTMLElement
  start: number
  stop: number
  first: boolean
  last: boolean
}
export interface HighlightTextProps {
  timestamp: number
  delay: number
  textHighlightPrimaryColor: string
  textHighlightSecondaryColor: string
  textHighlightFontColor?:string
  followHighlight?: boolean
}
const DEFAULT_WORD_DURATION = 0.3
const SECONDARY_GRACE = 0.05

export const clearHighlighting = () => {
  // get an array of all data-voice-intuitive-index elements and remove all background-color attributes
  document.querySelectorAll('[data-voice-intuitive-index]').forEach((span) => {
    if (!(span instanceof HTMLElement)) {
      return
    }
    span.style.removeProperty('background-color')
  })
}

export function highlightText (
  props: HighlightTextProps
) {
  getElementsWithRanges()
    .forEach(e => highlightElement(e, props))
}

function highlightElement (e: ElementAndRange, props: HighlightTextProps) {
  if (isPrimaryHighlight(e, props)) {
    doHighlight(e, props.textHighlightPrimaryColor, props.followHighlight,props.textHighlightFontColor || "")
  } else if (isSecondaryHighlight(e, props)) {
    if(props.textHighlightSecondaryColor)
      doHighlight(e, props.textHighlightSecondaryColor, false,props.textHighlightFontColor || "")
  } else {
    e.element.style.removeProperty('background-color')
    e.element.style.removeProperty('color')
  }
}

function isPrimaryHighlight (e: ElementAndRange, props: HighlightTextProps) {
  return isInRange(e, props, 0)
}
function isSecondaryHighlight (e: ElementAndRange, props: HighlightTextProps) {
  return isInRange(e, props, SECONDARY_GRACE)
}
function isInRange (e: ElementAndRange, props: HighlightTextProps, grace: number) {
  const from = e.start + (e.first ? props.delay : 0) - grace
  const to = e.stop + grace
  return from <= props.timestamp && to >= props.timestamp
}
function doHighlight (e: ElementAndRange, backgroundColor: string, shouldScroll = false,textHighlightFontColor:string) {
  e.element.style.backgroundColor = backgroundColor
  if(textHighlightFontColor) e.element.style.color = textHighlightFontColor;
  if (shouldScroll) {
    e.element.scrollIntoView({
      behavior: 'smooth',
      block: 'center'
    })
  }
  // if true, this is the first element, else this is the only element
  e.element.style.borderRadius = (e.first || e.last) ? '4px' : '0 4px 4px 0'
}

function getElementsWithRanges () {
  const ret: ElementAndRange[] = []
  const elements = getElements()
  for (let index = 0; index < elements.length; index++) {
    const element = elements[index]
    const start = getTimestamp(element) ?? 0
    const next = elements[index + 1]

    ret.push({
      element,
      start,
      stop: getTimestamp(next) ?? start + DEFAULT_WORD_DURATION,
      first: index === 0,
      last: !next
    })
  }
  return ret
}
function getElements (): HTMLElement[] {
  return [...document.querySelectorAll(
    '[data-voice-intuitive-timestamp]'
  )].filter(e => e instanceof HTMLElement).map(e => e as HTMLElement)
}
function getTimestamp (element?: HTMLElement) {
  const asString = element?.dataset.voiceIntuitiveTimestamp
  return asString ? parseFloat(asString) : undefined
}
