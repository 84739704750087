import guessLanguage from '../../../services/guessLanguage'
import { extractSsmlAndInjectOffsets } from './extractSsmlAndInjectOffsets'
import { Configurations, Exclusions } from '../../../models'
import { VoiceMode } from '../../../context/ConfigContext'
import { getVoiceOptions } from './getVoiceOptions'
// this function creates the SSML xml document sent to backend
export const ssmlGenerator = (
  elements: Array<HTMLElement> | null,
  //element: HTMLElement | null,
  excludeText: Exclusions,
  voiceMode: VoiceMode = 'clean',
  appConfig:Configurations
) => {
  if (!elements) { throw new Error('No element selected') }
  const lang = guessLanguage()
  //rate and pitch are remainders of voicemode stuff, but <prosody> had to be left out because of backend bug
  const { speechVoice, voiceGender, voiceStyle, rate, pitch } = getVoiceOptions(lang, voiceMode)
  if (!speechVoice) { throw new Error('unsupported speech language') }
  const header = `<speak
        version="1.0" xmlns="http://www.w3.org/2001/10/synthesis"
        xmlns:mstts="https://www.w3.org/2001/mstts"
        xml:lang="${lang}">
        <voice xml:lang="${lang}"
            xml:gender="${voiceGender}"
            name="${speechVoice}">
              <mstts:express-as style="${voiceStyle}">`
  const footer = ` </mstts:express-as>
        </voice>
    </speak>`
  let prevLength = header.length;
  const pageContent = 
    elements.map(element=>{
      const ssml = extractSsmlAndInjectOffsets(element, excludeText, prevLength,appConfig,lang)
      prevLength += (ssml.length+1); //+1 between every ssml to account for some endchar maybe? speaking starts to drift otherwise
      return ssml;
    })

  if (!pageContent) {
    throw new Error(
      'Voice Intuitive failed to find any text content on this page.'
    )
  }
  return header + pageContent + footer
}
