export interface FetchSpeechResponse {
  contentLength: number
  body: ReadableStream<Uint8Array>
}
export async function fetchSpeech (ssmlDoc: string,productionAPIUrl:string, useDevAPI: boolean,devAPIUrl:string): Promise<FetchSpeechResponse> {
  const response = await doFetch(ssmlDoc,productionAPIUrl, useDevAPI,devAPIUrl)
  return {
    contentLength: getContentLength(response),
    body: response.body! // this is null checked but TS fails to realize it
  }
}
async function doFetch (ssmlDoc: string,productionAPIUrl:string, useDevAPI: boolean,devAPIUrl:string) {
  // const url = useDevAPI
  //   ? 'https://voiceintuitive.azure-api.net/dev/tts'
  //   : 'https://voiceintuitive.azure-api.net/tts'

  let url = "";
  if(useDevAPI){
    if(devAPIUrl){
      url = devAPIUrl+"/tts";
    }
    else{
      if(productionAPIUrl.startsWith('https://api-stage.voicehub.fi')){
        url = 'https://api-dev.voicehub.fi/tts'
      }
      else if(productionAPIUrl.startsWith('https://api.voiceintuitive.com')){
        url = productionAPIUrl+"/tts"
      }
      else {
        url = productionAPIUrl+'/dev/tts';
      }
    }
  }
  else{
    url = productionAPIUrl+'/tts'
  }

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/ssml+xml',
      'X-Article-Path': window.location.pathname
    },
    body: ssmlDoc
  })
  validateResponse(response)
  return response
}
function validateResponse (response: Response) {
  if (!response.body) {
    throw new Error('Response does not have a body')
  }
  if (!response.ok) {
    console.error(response)
    throw new Error(`Request failed with status code:${response.status}`)
  }
}
function getContentLength (response: Response) {
  // when streaming, content encoding header is not set
  // but neither is x-file-size or content-length for that matter
  // so this is legacy code that doesn't really do anything
  const contentEncoding = response.headers.get('content-encoding')
  const contentLength = response.headers.get(
    contentEncoding ? 'x-file-size' : 'content-length'
  )
  if (!contentLength) {
    console.error('response missing content length', response.headers)
    return -1
  }
  return parseInt(contentLength, 10)
}
