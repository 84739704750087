const EVENTNAME = "voice-intuitive_player_status"

export const emitGlobalEventInfo = (message:string) =>{
    const event = new CustomEvent(EVENTNAME, {
        bubbles: true,
        cancelable: true,
        detail:message
    });

    document.dispatchEvent(event);
} 


