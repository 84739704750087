import { h } from 'preact'
import { LanguageDropdown } from './LanguageDropdown'
import { AppContext } from '../../context/AppContext'
import { StyleSheetManager } from 'styled-components'
import GlobalFonts from '../../assets/fonts'
import { AppConfigurations } from '../../context/ConfigContext'

interface DetachedLanguageDropdownProps {
  config: AppConfigurations
  element?: HTMLElement
  unmount: () => void
  onReset: () => void
  onTranslate: (languageCode: string) => void
}
export function DetachedLanguageDropdown (
  { config, unmount, element, onReset, onTranslate }: DetachedLanguageDropdownProps) {
  return (
      <AppContext config={config} element={element}>
        <StyleSheetManager target={element}>

            <LanguageDropdown
                setDropdownOpen={unmount}
                onTranslate={l => {
                  onTranslate(l)
                  unmount()
                }}
                onReset={onReset}
                wrapWithShadow={false}
            />

        </StyleSheetManager>

        <GlobalFonts />
      </AppContext>
  )
}
