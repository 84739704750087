import { h } from 'preact'
import styled from 'styled-components'
import { useAppConfig } from '../../../context/ConfigContext'

const Svg = styled.svg`
  &:hover > path {
    opacity: 0.7;
  }
`
interface Props {
  size?: number
}
export function SkipSymbol ({ size = 28 }: Props) {
  const { controlPanelForegroundColor } = useAppConfig()

  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5798 11.3229V18.3321C10.5798 18.7007 10.268 18.9858 9.90776 18.9858C9.53592 18.9858 9.248 18.7007 9.248 18.3321V12.4868L8.76803 12.7604C8.66023 12.8317 8.54015 12.8554 8.43201 12.8554C8.20413 12.8554 7.98819 12.7365 7.86845 12.5227C7.66411 12.2257 7.7726 11.8103 8.08439 11.6316L9.56014 10.7645C9.57208 10.7527 9.59596 10.7527 9.60824 10.7409C9.63212 10.7409 9.64406 10.7172 9.66828 10.7054C9.69216 10.7054 9.71638 10.6936 9.74026 10.6936C9.7522 10.6817 9.77608 10.6817 9.78836 10.6817C9.81224 10.6699 9.8484 10.6699 9.87228 10.6699C9.88422 10.6699 9.89616 10.6699 9.9081 10.6699H9.92004C9.95586 10.6699 9.98008 10.6699 10.0043 10.6699C10.0162 10.6817 10.0401 10.6817 10.0524 10.6817C10.0882 10.6817 10.1005 10.6936 10.1244 10.7054C10.1363 10.7054 10.1602 10.7054 10.1725 10.7172C10.2083 10.7409 10.2206 10.7527 10.2445 10.7527C10.2564 10.7645 10.2683 10.7763 10.2926 10.7881C10.3045 10.8 10.3284 10.8118 10.3407 10.8236C10.3645 10.8354 10.3765 10.8591 10.3888 10.8712C10.401 10.8834 10.4126 10.8949 10.4246 10.9189C10.4365 10.9307 10.4604 10.9543 10.4727 10.9665L10.4846 10.9901C10.4846 11.002 10.4966 11.0141 10.4966 11.0259C10.5085 11.0496 10.5204 11.0614 10.5204 11.0854C10.5324 11.109 10.5324 11.133 10.5443 11.1448C10.5443 11.1685 10.5563 11.1925 10.5563 11.2043C10.5563 11.2398 10.5563 11.2637 10.5801 11.2874C10.5798 11.2989 10.5798 11.3107 10.5798 11.3229Z"
        fill={controlPanelForegroundColor}
      />
      <path
        d="M18.3303 13.9474C18.918 14.5176 19.2056 15.278 19.2056 16.0383C19.2056 16.7868 18.9057 17.5472 18.3303 18.1174C17.7541 18.6997 16.9865 18.9844 16.2302 18.9844C16.2063 18.9844 16.2063 18.9844 16.2063 18.9844C15.4627 18.9844 14.6825 18.6993 14.1186 18.1174C13.8426 17.8681 13.8426 17.4401 14.1186 17.1672C14.3707 16.9057 14.8145 16.9057 15.0789 17.1672C15.3907 17.4877 15.7984 17.6421 16.2302 17.6421C16.6386 17.6421 17.0585 17.4877 17.3703 17.1672C17.6821 16.8581 17.8499 16.4423 17.8499 16.0383C17.8499 15.6107 17.694 15.2067 17.3703 14.898C17.0585 14.5771 16.6508 14.4345 16.2302 14.4345C15.8226 14.4345 15.3907 14.5771 15.0789 14.898C14.8145 15.1591 14.3826 15.1591 14.1186 14.898C14.0705 14.8503 14.0347 14.7909 13.9866 14.7433C13.9866 14.7196 13.9866 14.7078 13.9746 14.7078C13.9746 14.6841 13.9627 14.6602 13.9508 14.6483C13.9508 14.6129 13.9388 14.5889 13.9388 14.5652C13.9388 14.5534 13.9269 14.5534 13.9269 14.5416C13.9149 14.4703 13.9149 14.3991 13.9269 14.3396L14.2868 11.2628C14.3226 11.0608 14.4307 10.8942 14.5866 10.7875C14.6105 10.7757 14.6225 10.7757 14.6347 10.7639C14.647 10.752 14.6586 10.752 14.6706 10.7402C14.7664 10.6926 14.8623 10.6689 14.9824 10.6689H18.5223C18.9061 10.6689 19.2059 10.9659 19.2059 11.3462C19.2059 11.7026 18.9061 12.0116 18.5223 12.0116H15.5708L15.4269 13.1874C15.6906 13.1162 15.9665 13.0807 16.2302 13.0807C16.9865 13.0804 17.7541 13.3655 18.3303 13.9474Z"
        fill={controlPanelForegroundColor}
      />
      <path
        d="M27.5494 13.6916C27.3007 13.6916 27.0997 13.8909 27.0997 14.1371C27.0997 21.29 21.2223 27.1096 13.9985 27.1096C6.77494 27.1096 0.897867 21.29 0.897867 14.1371C0.897867 6.98294 6.77494 1.16264 13.9985 1.16264C16.3966 1.16264 18.6925 1.79497 20.7178 2.99882L20.4312 3.28424L19.0442 4.65766C18.8364 4.8556 18.7897 5.08563 18.9033 5.3447C19.0183 5.60919 19.2144 5.74227 19.4965 5.74227H23.9971C24.1725 5.74227 24.3874 5.67877 24.515 5.55244C24.6412 5.42543 24.7705 5.2785 24.7705 5.10522V0.648537C24.7705 0.36953 24.575 0.172941 24.313 0.0601226C24.0401 -0.0533718 23.7716 -0.00574464 23.5786 0.199288L22.2553 1.48319L21.3776 2.34926C19.167 0.987669 16.6402 0.271573 14.0022 0.271573C6.28234 0.271573 0 6.49147 0 14.1368C0 21.7808 6.27961 28 13.9995 28C21.7197 28 28 21.7811 28 14.1368C27.9997 13.8912 27.7977 13.6916 27.5494 13.6916Z"
        fill={controlPanelForegroundColor}
      />
    </Svg>
  )
}
