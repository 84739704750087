import { h } from 'preact'
import styled from 'styled-components'
import { Status } from '../../../models'
import { InlineButtonText } from './InlineButtonText'
import { InlineButtonSymbol } from './InlineButtonSymbol'
import { useAppConfig } from '../../../context/ConfigContext'
import { usePlayerController } from '../../../context/PlayerContext'
import { InlineButtonPlaintext } from './InlineButtonPlaintext'

const Svg = styled.svg<{ status: Status }>`
  height: 44px;
  width: 150px;
  ${(props) => props.status === 'disabled' && 'opacity: 0.3;'}
  &:hover .highlightable path,
  &:hover .highlightable circle {
    opacity: 0.7;
    ${(props) => props.status === 'loading' && 'opacity: 1;'}
    ${(props) => props.status === 'error' && 'opacity: 1;'}
    ${(props) => props.status === 'disabled' && 'opacity: 1;'}
  }
`

const smallStyleObject = {
  transform:"scale(0.8)",
  transformOrigin:"left"
}

export function InlinePlay () {
  const { buttonBackgroundColor,smallSize } = useAppConfig()
  const { status } = usePlayerController()

  return (
    <div style={Object.assign({ position: 'relative' ,height:"44px"},smallSize ? smallStyleObject:{})}>
      <Svg
        viewBox="0 0 225 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        status={status}
      >
        <g className={'highlightable'}>
          <InlineButtonText />
          <circle cx="32" cy="32" r="32" fill={buttonBackgroundColor} />
        </g>
        <InlineButtonSymbol/>
      </Svg>
      <InlineButtonPlaintext color={buttonBackgroundColor}/>

    </div>
  )
}
