/**
 * 
 * @param langCode 
 * @returns Input ISO 639-1 language code to get the corresponding ISO 3166-1 alpha-2 country code.
 */
export const languageToCountryCode = (langCode:string):string=> {
    const langList = {
        'en': 'gb',  // English - United Kingdom
        'fi': 'fi',  // Finnish - Finland
        'sv': 'se',  // Swedish - Sweden
        'ar': 'sa',  // Arabic - Saudi Arabia
        'zh': 'cn',  // Chinese - China
        'da': 'dk',  // Danish - Denmark
        'nl': 'nl',  // Dutch - Netherlands
        'et': 'ee',  // Estonian - Estonia
        'fr': 'fr',  // French - France
        'de': 'de',  // German - Germany
        'hi': 'in',  // Hindi - India
        'is': 'is',  // Icelandic - Iceland
        'ja': 'jp',  // Japanese - Japan
        'ko': 'kr',  // Korean - South Korea
        'no': 'no',  // Norwegian - Norway
        'ru': 'ru',  // Russian - Russia
        'es': 'es',  // Spanish - Spain
        'uk': 'ua',  // Ukrainian - Ukraine
        'so': 'so',   // Somalian - Somalia
        'fa': 'ir',  // persian - iran
        'th': 'th',  // thai - thailand
        'tr': 'tr',  // turkish - turkey
        'vi': 'vn',  // vietnamese - vietnam
        'hu': 'hu',  // hungarian - hungary
        'id': 'id',   // indonesian - indonesia
        'sq': 'al',   // albanian - albania
        'ku': 'ku',  // kurdish - kurdistan

    };
    return langList[langCode];
}