import styled from 'styled-components'
import { h } from 'preact'
import { ProgressIndicator } from './ProgressIndicator'
import { usePlayerController } from '../../../context/PlayerContext'
import { useAppConfig } from '../../../context/ConfigContext'
import { timeToString } from './CurrentItemIndicator'

interface Props {
  progress: number
}

export function SlimSeekBar ({ progress }: Props) {
  const playerController = usePlayerController()
  const { seekBarColor } = useAppConfig()
  const progressString = timeToString(playerController.getTime())
  const totalString = timeToString(playerController.getLength())
  return <SeekBar onClick={e => playerController.seek(calculateProgressFromClick(e))} title={progressString + '/' + totalString} style={{"background":progress===0 ? "initial":"#ddd"}}>
                <ProgressIndicator progress={progress} color={seekBarColor ?? 'gray'} height={4} opacity={0.9} shadow={true} rounded={true}/>
            </SeekBar>
}

function calculateProgressFromClick (e: React.MouseEvent<HTMLDivElement>) {
  const bounds = (e.target as HTMLElement).getBoundingClientRect()
  const x = e.clientX - bounds.left
  return x / (bounds.width)
}

const SeekBar = styled.div`
  // position: absolute;
  // top:-5px;
  // width:100%;
  // height:5px;
  position: relative;
  top: 0px;
  width: 39%;
  height: 4px;
  margin-left: 12px;
  
`
