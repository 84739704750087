const createShadowRoot = (
  rootElementSelector: string, 
  containerId: string, 
  zIndex = '1000000000',
  prependButtonsInButtonContainer=false,
  extraCSSString="") => {
  const rootElement = document.querySelector(rootElementSelector)

  if (!rootElement) {
    throw new Error(`Failed to create element with selector ${rootElementSelector}.`)
  }

  const preExistingShadowRoot = window.document.getElementById(containerId)

  // remove any pre-existing roots with the same name
  if (preExistingShadowRoot) {
    preExistingShadowRoot.remove()
  }

  // create an element inside which the shadow root will be placed
  // if shadow is attached directly to the element, everything else inside it will visually disappear
  const shadowContainer = window.document.createElement('div')

  // give the root an ID
  shadowContainer.id = containerId
  if(containerId === "voice-intuitive-translator"){
    //interactive elements shouldn't apparently be placed inside each other based on accessibility
    //https://dequeuniversity.com/rules/axe/4.10/nested-interactive
    //shadowContainer.setAttribute('role',"button");
    shadowContainer.lang="en"
  }
  const shadow = shadowContainer.attachShadow({ mode: 'open' })
  if(prependButtonsInButtonContainer){
    rootElement.insertBefore(shadowContainer, rootElement.children[1]);
  }
  else{
    rootElement.appendChild(shadowContainer)
  }
  const shadowRoot = shadow.appendChild(window.document.createElement('div'))
  // styling
  //shadowRoot.style.zIndex = zIndex
  //shadowRoot.style.position = 'relative'
  shadowContainer.style.zIndex = zIndex
  shadowContainer.style.position = 'relative'
  shadowContainer.style.cssText += extraCSSString;
  return shadowRoot
}

export default createShadowRoot
