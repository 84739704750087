import { WordMetaData } from '../audio/WordMetaData'
import { createHighLightableSpans } from './createHighLightableSpans'

export function injectWordMetaData (audio: HTMLAudioElement, ssmlDocument: string, metaData: WordMetaData) {
  const word = ssmlDocument.substring(metaData.textoffset, metaData.textoffset + metaData.wordlength)
  findValidParentElements(metaData)
    .forEach(e => createHighLightableSpans(
      e,
      audio,
      metaData,
      word
    ))
}
function findValidParentElements (metaData: WordMetaData) {
  const selector = 'span[data-voice-intuitive-text-offset-start]'
  const parentElements = document.querySelectorAll(selector)
  return [...parentElements].filter(e => parentElementIsValid(e, metaData))
}
function parentElementIsValid (parentElement: Element, { textoffset }: WordMetaData) {
  return parentElement instanceof HTMLElement &&
        parentElement.dataset &&
        parentElement.dataset.voiceIntuitiveTextOffsetStart &&
        parentElement.dataset.voiceIntuitiveTextOffsetEnd &&
        parseInt(
          parentElement.dataset.voiceIntuitiveTextOffsetStart,
          10
        ) <= textoffset &&
        parseInt(
          parentElement.dataset.voiceIntuitiveTextOffsetEnd,
          10
        ) >= textoffset
}
