import { h } from 'preact'
import styled from 'styled-components'
import { useLocalizations } from '../../../localizations'
import { usePlayerController, usePlaylistController } from '../../../context/PlayerContext'

interface PlayProps {
  children: JSX.Element
  extraStyles: string
}

const Button = styled.button<{
  loading: boolean
  extraStyles: string
}>`
  cursor: pointer;
  background-color: transparent;
  border: none;
  &:disabled {
    cursor: not-allowed;
  }
  ${(props) => props.loading && 'cursor: wait;'}
  ${(props) => props.extraStyles}
`

// this is a container element that has the play button and its business logic
// it should be fed another element as a child as the child determines the buttons appearance
export function PlayButton (props: PlayProps) {
  const playerController = usePlayerController()
  const playlistController = usePlaylistController()
  const localizations = useLocalizations()
  const status = playerController.status
  return (
    <Button
      id='voiceintuitive-button-play'
      aria-label={status!== "playing" ? localizations.listen:localizations.pause}
      onClick={async () => {
        if (!playlistController.initialized && playlistController.playlistWidgetOpen) {
          await playlistController.initAndPlay()
        } else { await playerController.handleClick() }
      }}
      loading={status === 'loading'}
      disabled={status === 'error' || status === 'disabled'}
      extraStyles={props.extraStyles}
      title={
        status === 'disabled'
          ? 'Listening in this language is not supported.'
          : ''
      }
    >
      {props.children}
    </Button>
  )
}
