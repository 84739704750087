import { VoiceMode } from '../../../context/ConfigContext'
import { speechVoicesClean, speechVoicesSoft } from './speechVoices'

export interface VoiceOptions {
  speechVoice: string
  rate: string
  pitch: string
  voiceGender: string
  voiceStyle: string
}

export function getVoiceOptions (lang: string, voiceMode: VoiceMode): VoiceOptions {
  switch (voiceMode) {
    case 'clean':
      return {
        pitch: '0%',
        rate: '0%',
        speechVoice: speechVoicesClean[lang],
        voiceGender: 'Female',
        voiceStyle: 'general'
      }
    case 'soft':
      return {
        pitch: '-5%',
        rate: '5%',
        speechVoice: speechVoicesSoft[lang],
        voiceGender: 'Male',
        voiceStyle: 'general'
      }
  }
}

export function isLanguageSupported (lang: string) {
  return !!getVoiceOptions(lang, 'clean').speechVoice
}
