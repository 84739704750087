import { h } from 'preact'
import styled, { keyframes } from 'styled-components'
import { usePlayerController } from '../../../context/PlayerContext'
import { useAppConfig } from '../../../context/ConfigContext'

const rotate = keyframes`
 0% { transform: rotate(0deg); }
 100% { transform: rotate(360deg); }
`

const RotatingGroup = styled.g`
  transform-origin: 32px 50%;
  animation: ${rotate} 1.2s linear infinite;
`
export function InlineButtonSymbol () {
  const { status } = usePlayerController()
  const { buttonForegroundColor } = useAppConfig()

  switch (status) {
    case 'playing':
      return (
        <g>
          <rect
            x="24"
            y="19"
            width="4"
            height="25"
            rx="2"
            fill={buttonForegroundColor}
          />
          <rect
            x="36"
            y="19"
            width="4"
            height="25"
            rx="2"
            fill={buttonForegroundColor}
          />
        </g>
      )
    case 'uninitialized':
    case 'paused':
    case 'disabled':
      return (
        <path
          d="M26.8205 41.2454L42.9612 32.8843C43.6272 32.5298 43.9532 32.2072 43.9334 31.7008C43.9177 31.2462 43.49 30.9338 42.9612 30.6595L26.8205 22.2969C26.5925 22.1816 25.9494 21.855 25.4964 21.8733C25.188 21.8733 24.2037 21.8712 24.2037 23.8862V38.6082L20.5303 40.4419C20.5044 40.1778 20.4927 39.9167 20.4927 39.6546V23.8862C20.4927 18.5706 24.0792 18.1582 25.1764 18.1582C26.8561 18.1582 28.3637 18.9145 28.5308 19.0014L44.6674 27.3625C46.9023 28.5206 48.0365 29.9703 48.0365 31.6688C48.0365 34.2994 45.2302 35.8887 44.6674 36.1813L28.5298 44.5425C27.3163 45.1713 26.2303 45.4781 25.2114 45.4781C23.6449 45.4771 22.5549 44.8538 21.7798 43.812L26.8205 41.2454Z"
          fill={buttonForegroundColor}
        />
      )
    case 'loading':
      return (
        <RotatingGroup>
          <path
            d="M31.636,51.997L32,52C35.079,52 37.995,51.304 40.601,50.061C41.597,49.586 42.02,48.391 41.545,47.395C41.07,46.398 39.875,45.975 38.879,46.451C36.86,47.413 34.608,47.965 32.23,47.998L32,48C30.443,48 28.938,47.778 27.514,47.363C21.734,45.679 17.306,40.821 16.244,34.802C16.052,33.715 15.014,32.988 13.927,33.18C12.84,33.371 12.113,34.41 12.305,35.497C13.612,42.905 18.997,48.907 26.058,51.102L26.395,51.203C28.062,51.689 29.82,51.964 31.636,51.997ZM46.555,25.345C47.483,27.372 48,29.625 48,32C48,35.823 46.659,39.333 44.422,42.085C43.726,42.942 43.856,44.202 44.712,44.899C45.569,45.595 46.829,45.465 47.526,44.609C50.323,41.168 52,36.78 52,32C52,29.031 51.352,26.213 50.192,23.679C48.947,20.961 47.11,18.57 44.847,16.671C44.001,15.962 42.739,16.072 42.029,16.918C41.32,17.764 41.43,19.026 42.276,19.736C44.088,21.256 45.558,23.169 46.555,25.345ZM16.871,18.918C14.951,21.137 13.512,23.782 12.712,26.693C12.42,27.757 13.047,28.859 14.111,29.151C15.176,29.443 16.277,28.816 16.57,27.752C17.209,25.425 18.361,23.31 19.896,21.536C22.83,18.145 27.165,16 32,16C33.384,16 34.728,16.175 36.009,16.506C37.078,16.782 38.17,16.138 38.445,15.069C38.721,14 38.077,12.908 37.008,12.633C35.408,12.22 33.729,12 32,12C25.956,12 20.539,14.681 16.871,18.918Z"
            fill={buttonForegroundColor}
          />
        </RotatingGroup>
      )
    case 'error':
      return (
        <path
          d="M32.432 39.392C31.344 39.392 30.752 38.784 30.656 37.568L29.264 19.904C29.2 18.912 29.456 18.08 30.032 17.408C30.64 16.736 31.424 16.4 32.384 16.4C33.344 16.4 34.128 16.736 34.736 17.408C35.344 18.08 35.6 18.912 35.504 19.904L34.16 37.568C34.064 38.784 33.488 39.392 32.432 39.392ZM32.096 50C31.168 50 30.448 49.76 29.936 49.28C29.456 48.768 29.216 48.048 29.216 47.12V46.304C29.216 45.376 29.456 44.672 29.936 44.192C30.448 43.68 31.168 43.424 32.096 43.424H32.72C33.648 43.424 34.352 43.68 34.832 44.192C35.344 44.672 35.6 45.376 35.6 46.304V47.12C35.6 48.048 35.344 48.768 34.832 49.28C34.352 49.76 33.648 50 32.72 50H32.096Z"
          fill={buttonForegroundColor}
        />
      )
  }
}
