import { h } from 'preact'
import Translate from './TranslateButton'
import { StyleSheetManager } from 'styled-components'
import { createPortal } from 'preact/compat'
import createShadowRoot from '../../services/createShadowRoot'
import { useAppConfig } from '../../context/ConfigContext'

// @deprecated
export default () => {
  const { 
    translationButtonContainer, 
    buttonContainerSelector,
    prependButtonsInButtonContainer,
    smallSize } = useAppConfig()

  // use default root for translator if no root is specified in config
  const translatorContainerId = translationButtonContainer ?? buttonContainerSelector ?? '#voice-intuitive-root'

  const translatorShadow = createShadowRoot(
    translatorContainerId,
    'voice-intuitive-translator',
    '1',
    prependButtonsInButtonContainer ? true:false
  )

  return createPortal(
    <StyleSheetManager target={translatorShadow}>
      <div style={smallSize ? {transform:"scale(0.8)",transformOrigin:"left"}:{}}>
        <Translate />
      </div>
    </StyleSheetManager>,
    translatorShadow
  )
}
