import { Cross } from './Playlist.styles'
import { h } from 'preact'
import { RemoveIcon } from './RemoveIcon'
interface Props {
  onClose: () => void
  color: string
}
export function PlaylistCloseButton ({ onClose, color }: Props) {
  return <Cross onClick={onClose}>
        <svg
            width="18"
            height="18"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <RemoveIcon color={color}/>
        </svg>
    </Cross>
}
