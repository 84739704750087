import guessLanguage from '../../../services/guessLanguage'
import { useLocalizations } from '../../../localizations'
import { h } from 'preact'

interface Props {
  color: string
}
export function InlineButtonPlaintext ({ color }: Props) {
  const lang = guessLanguage()
  const { listen } = useLocalizations()
  if (listen === 'Listen' || lang === 'fi' || lang === 'sv') { return null } // rendered as svg

  return <div style={{
    position: 'absolute',
    color,
    top: 8,
    left: 48,
    textTransform: 'uppercase',
    fontFamily: 'Quicksand',
    fontSize: 16,
    fontWeight: 'bold',
    letterSpacing: '0.2rem'
  }}>
      {listen}
  </div>
}
