import { ComponentChildren, createContext, h } from 'preact'
import { useContext, useState } from 'preact/hooks'
import guessLanguage from '../services/guessLanguage'
import { TranslatorController } from '../translator/TranslatorController'
import { useAppConfig } from './ConfigContext'
import { usePlayerController } from './PlayerContext'
import { CustomTranslations } from '../translator/translatorServices/CustomTranslations'

interface TranslatorGlobals {
  translatorController: TranslatorController
}

const Ctx = createContext<TranslatorGlobals>(
  // @ts-expect-error
  {} 
)
interface Props {
  children: ComponentChildren
}
export function TranslatorContextProvider ({ children }: Props) {
  const config = useAppConfig()
  const playerController = usePlayerController()
  const customTranslations = 
    config.customTranslationsData ? 
      new CustomTranslations(
        config.customTranslationsData.lockedWords || [],
        config.customTranslationsData.globalPreprocess || {},
        config.customTranslationsData.globalTranslations || {},
        config.customTranslationsData.translations || {},
        config.customTranslationsData.globalPostprocess || {},
        config.customTranslationsData.postprocess || {}) : 
      new CustomTranslations()

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_trigger, setRefreshTrigger] = useState(0)
  const [isTranslated,setIsTranslated] = useState(false)
  const [originalLanguage] = useState(guessLanguage(document));
  const translatorController = new TranslatorController({
    config,
    originalLanguage: originalLanguage,
    refreshTrigger () {
      setRefreshTrigger(Math.random())
    },
    playerController,
    customTranslations,
    isTranslated,
    setIsTranslated

  })
  return <Ctx.Provider value={{ translatorController }}>{children}</Ctx.Provider>
}
export function useTranslatorController () {
  return useContext(Ctx).translatorController
}
