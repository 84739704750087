import { ComponentChildren, h } from 'preact'
import { AppConfigurations, ConfigContextProvider } from './ConfigContext'
import { PlayerContextProvider } from './PlayerContext'
import { EventHandlersWrapper } from '../events/EventHandlersWrapper'
import { TranslatorContextProvider } from './TranslatorContext'

interface Props {
  children: ComponentChildren
  config: AppConfigurations
  element?: HTMLElement
}

export const AppContext = ({ children, config, element }: Props) => {
  return (

    <ConfigContextProvider value={config}>
      <PlayerContextProvider>
          <TranslatorContextProvider>
            <EventHandlersWrapper element={element}>{children}</EventHandlersWrapper>
          </TranslatorContextProvider>
      </PlayerContextProvider>
    </ConfigContextProvider>
  )
}
