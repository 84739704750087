import styled from 'styled-components'

export const PlaylistWrapper = styled.div<{ backgroundColor: string }>`
  position: fixed;
  bottom: 70px;
  left: 0;
  max-height: 500px;
  height: calc(100% - 70px);
  width: 100%;
  box-shadow: 0px 0px 25px #a9a9a9;
  background-color: ${(props) => props.backgroundColor};
`

export const PlaylistHeader = styled.div`
  margin-bottom: 15px;
`

export const Tabs = styled.div`
  display: inline-block;
  position: relative;
  left: 50%;
  transform: translate(-50%);
`

export const Tab = styled.button`
  font-size: 16px;
  font-variation-settings: "wght" 600;
  cursor: pointer;
  background-color: transparent;
  border: none;
  padding: 0 10px;
  &:hover {
    opacity: 0.7;
  }
`

export const ActiveTabIndicator = styled.div<{ foregroundColor: string }>`
  display: block;
  width: 100%;
  height: 2px;
  background-color: ${(props) => props.foregroundColor};
`

export const Headline = styled.h1<{ foregroundColor: string }>`
  font-family: quicksand;
  text-align: center;
  color: ${(props) => props.foregroundColor};
  margin-bottom: 0;
`

export const TabButtonText = styled.p<{ opacity: number, foregroundColor: string }>`
  font-family: quicksand;
  margin: 12px 0 4px;
  font-weight: 600;
  color: ${(props) => props.foregroundColor};
  opacity: ${(props) => props.opacity};
`

export const PlaylistLinkContainer = styled.div`
  max-width: 768px;
  height: calc(100% - 110px);
  margin: auto;
  padding: 0 15px;
  white-space: nowrap;
  overflow-x: hidden;
  overflow-y: auto;
`

export const Cross = styled.button`
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 100000;
  cursor: pointer;
  background-color: transparent;
  border: none;
  padding: 5px;
  &:hover {
    opacity: 0.7;
  }
`
