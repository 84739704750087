import { h } from 'preact'
import styled from 'styled-components'
import { useAppConfig } from '../../../context/ConfigContext'

const Svg = styled.svg`
  &:hover > path {
    opacity: 0.7;
  }
  
`
interface Props {
  size?: number
  prev?: boolean
  disabled?: boolean
}
export function NextSymbol ({ size = 28, prev, disabled }: Props) {
  const { controlPanelForegroundColor, controlPanelShadowColor } = useAppConfig()

  return (
  <div style={{    
    // transform: prev ? 'scale(-1,1)' : 'scale(1,1)',
    transform: prev ? 'scale(1,1)' : 'scale(-1,1)',
    display: 'inline-block'
  }}>

    <Svg 
      width={size} 
      height={size} 
      viewBox="0 0 5 9" 
      xmlns="http://www.w3.org/2000/svg">
      <path 
        // fill="#014D66"
        fill={disabled ? controlPanelShadowColor : controlPanelForegroundColor} 
        fill-rule="evenodd" 
        clip-rule="evenodd" 
        d="M3.94055 0.576878C4.11218 0.407968 4.36952 0.407968 4.54115 0.576878C4.71279 0.742926 4.71279 1.01493 4.54115 1.18385L1.02243 4.70235L4.31209 7.97462C4.45511 8.14067 4.45511 8.41267 4.31209 8.58158C4.14045 8.74763 3.85462 8.74763 3.68298 8.58158L0.107271 5.00584C-0.0357571 4.83692 -0.0357571 4.56491 0.107271 4.396L3.94055 0.576878Z" 
        />
    </Svg>
  </div>
  )
}
