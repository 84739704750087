import { h } from 'preact'
import styled from 'styled-components'
import { useAppConfig } from '../../../context/ConfigContext'

const Svg = styled.svg`
  &:hover > path,
  &:hover > rect {
    opacity: 0.7;
  }
`

export function PlaylistSymbol () {
  const { controlPanelForegroundColor } = useAppConfig()

  return (
    <Svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.078 18.744C15.966 18.744 15.868 18.7067 15.784 18.632C15.7093 18.548 15.672 18.4453 15.672 18.324C15.672 18.212 15.7093 18.1233 15.784 18.058C15.868 17.9833 15.966 17.946 16.078 17.946H21.86C21.972 17.946 22.0653 17.9833 22.14 18.058C22.224 18.1327 22.266 18.2307 22.266 18.352C22.266 18.4547 22.224 18.548 22.14 18.632C22.0653 18.7067 21.972 18.744 21.86 18.744H16.078ZM18.962 21.768C18.8313 21.768 18.724 21.726 18.64 21.642C18.556 21.558 18.514 21.4507 18.514 21.32V15.398C18.514 15.258 18.556 15.146 18.64 15.062C18.7333 14.978 18.8407 14.936 18.962 14.936C19.102 14.936 19.2093 14.978 19.284 15.062C19.368 15.146 19.41 15.2533 19.41 15.384V21.32C19.41 21.4507 19.368 21.558 19.284 21.642C19.2 21.726 19.0927 21.768 18.962 21.768Z"
        fill={controlPanelForegroundColor}
      />
      <rect
        width="22"
        height="1.25"
        rx="0.625"
        fill={controlPanelForegroundColor}
      />
      <rect
        y="9"
        width="22"
        height="1.25"
        rx="0.625"
        fill={controlPanelForegroundColor}
      />
      <rect
        y="18"
        width="12"
        height="1.25"
        rx="0.625"
        fill={controlPanelForegroundColor}
      />
    </Svg>
  )
}
