import { useState } from 'preact/hooks'

type PersistentState<T> = [T, (newValue: T) => void]
export function usePersistentState (key: string, defaultValue?: string): PersistentState<string | undefined> {
  const [state, setState] = useState<string | undefined>(fromLocalStorage(key) ?? defaultValue)

  return [state, (newValue: string | undefined) => {
    persistToLocalStorage(key, newValue)
    setState(newValue)
  }]
}
export function usePersistentJsonState<T> (key: string, defaultValue: T): PersistentState<T> {
  const [stringState, setStringState] = usePersistentState(key, JSON.stringify(defaultValue))
  return [parseJson(stringState, defaultValue), (v: T) => setStringState(JSON.stringify(v))]
}
export function usePersistentStringEnumState<T extends string> (key: string, defaultValue: T): PersistentState<T> {
  const [stringState, setStringState] = usePersistentState(key, JSON.stringify(defaultValue))
  return [stringState as T, setStringState]
}
export function usePersistentBooleanState (key: string, defaultValue: boolean = false): PersistentState<boolean> {
  const [stringState, setStringState] = usePersistentState(key, String(defaultValue))
  return [stringState === 'true', (b: boolean) => setStringState(String(b))]
}
export function usePersistentFloatState (key: string, defaultValue: number = 0): PersistentState<number> {
  const [stringState, setStringState] = usePersistentState(key, String(defaultValue))
  return [parseFloat(stringState ?? '') ?? defaultValue, (b: number) => setStringState(String(b))]
}
export function usePersistentIntState (key: string, defaultValue: number = 0): PersistentState<number> {
  const [stringState, setStringState] = usePersistentState(key, String(defaultValue))
  return [parseInt(stringState ?? '') ?? defaultValue, (b: number) => setStringState(String(b))]
}

function parseJson<T> (stringState: string | undefined, defaultValue: T) {
  if (!stringState) { return defaultValue }
  try {
    return JSON.parse(stringState) as T
  } catch (e) {
    return defaultValue
  }
}

function fromLocalStorage (key: string): string | undefined {
  try {
    return localStorage.getItem(toLocalStorageKey(key)) ?? undefined
  } catch (e) {
    console.warn("Failed reading from localStorage, this is probably because you're using Safari in private mode")
    return undefined
  }
}

function persistToLocalStorage (key: string, value?: string) {
  try {
    if (!value) {
      localStorage.removeItem(toLocalStorageKey(key))
    } else {
      localStorage.setItem(toLocalStorageKey(key), value)
    }
  } catch (e) {
    console.warn("Failed writing to localStorage, this is probably because you're using Safari in private mode")
  }
}

function toLocalStorageKey (key: string) {
  return 'voiceintuitive_' + key
}
