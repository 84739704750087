import { h } from 'preact'
import styled from 'styled-components'
import { useLocalizations } from '../../../localizations'
import { usePlaylistController } from '../../../context/PlayerContext'
import { NextSymbol } from '../symbols/NextSymbol'

const Button = styled.button`
  cursor: pointer;
  background-color: transparent;
  padding: 0;
  border: none;
`
interface Props {
  size?: number
}
export function NextButton ({ size }: Props) {
  const playlistController = usePlaylistController()
  const localizations = useLocalizations()
  const disabled = !playlistController.playlist.hasNext()
  return (
    <Button
      aria-label={localizations.nextButton}
      onClick={async () => !disabled && await playlistController.playNext()}
    >
      <NextSymbol size={size} disabled={disabled} />
    </Button>
  )
}
