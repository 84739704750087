import { h } from 'preact'
import styled from 'styled-components'
import { useLocalizations } from '../../../localizations'
import { usePlayerController } from '../../../context/PlayerContext'
import { SkipSymbol } from '../symbols/SkipSymbol'

const Button = styled.button`
  cursor: pointer;
  background-color: transparent;
  padding: 0;
  border: none;
`
interface Props {
  size?: number
}
export function GoForwardButton ({ size }: Props) {
  const playerController = usePlayerController()
  const localizations = useLocalizations()
  return (
    <Button
      aria-label={localizations.forwardButton}
      onClick={() => playerController.skip(15)}
    >
      <SkipSymbol size={size}/>
    </Button>
  )
}
