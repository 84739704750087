import { SsmlBlock } from './toSsmlBlocks'

export function injectOffsets (ssmlBlocks: SsmlBlock[], initialOffset: number) {
  ssmlBlocks.reduce((offset, block) => {
    const curLength = block.ssml.length
    const end = offset + curLength + 1 // +1 for joining chars
    injectOffsetsBySentenceId(block.sentenceId, offset, end)
    return end
  }, initialOffset)
}

function injectOffsetsBySentenceId (sentenceId: string | undefined, start: number, end: number) {
  const el = findElementBySentenceId(sentenceId)
  if (!el) { return }
  el.dataset.voiceIntuitiveTextOffsetStart = String(start)
  el.dataset.voiceIntuitiveTextOffsetEnd = String(end)
}
function findElementBySentenceId (sentenceId?: string) {
  if (!sentenceId) { return null }
  return document.querySelector(`span[data-voice-intuitive-sentence='${sentenceId}']`) as HTMLElement
}
