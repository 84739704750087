/**
 * 
 * check capabilities
 * https://learn.microsoft.com/en-us/azure/ai-services/speech-service/language-support?tabs=tts
*/
export const speechVoicesClean: Record<string, string> = {
  en: 'en-GB-LibbyNeural',
  sv: 'sv-SE-SofieNeural',
  fi: 'fi-FI-SelmaNeural',
  nl: 'nl-NL-FennaNeural',
  de: 'de-DE-KatjaNeural',
  no: 'nb-NO-IselinNeural',
  da: 'da-DK-ChristelNeural',
  fr: 'fr-FR-BrigitteNeural',
  ru: 'ru-RU-SvetlanaNeural',
  uk: 'uk-UA-PolinaNeural',
  et: 'et-EE-AnuNeural',
  ar: 'ar-AE-FatimaNeural',
  zh: 'zh-CN-XiaoxiaoNeural',
  ja: 'ja-JP-NanamiNeural',
  hi: 'hi-IN-SwaraNeural',
  is: 'is-IS-GudrunNeural',
  it: 'it-IT-BenignoNeural',
  es: 'es-ES-AbrilNeural',
  so: 'so-SO-UbaxNeural',
  ko: 'ko-KR-JiMinNeural',
  fa: 'fa-IR-DilaraNeural',
  th: 'th-TH-PremwadeeNeural',
  tr: 'tr-TR-EmelNeural',
  vi: 'vi-VN-HoaiMyNeural',
  hu: 'hu-HU-NoemiNeural',
  id: 'id-ID-GadisNeural',
  sq: 'sq-AL-AnilaNeural',

}

export const speechVoicesSoft: Record<string, string> = {
  ...speechVoicesClean,
  en: 'en-US-TonyNeural',
  fi: 'fi-FI-HarriNeural'
}
