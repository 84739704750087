import { ComponentChildren, h } from 'preact'
import { useCreateEventListeners } from './useCreateEventListeners'

interface Props {
  children: ComponentChildren
  element?: HTMLElement
}
export function EventHandlersWrapper ({ children, element }: Props) {
  useCreateEventListeners(element)
  return <div>{children}</div>
}
