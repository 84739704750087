import { h } from 'preact'
import { useState } from 'preact/hooks'

import styled from 'styled-components'
import { LanguageDropdown } from './LanguageDropdown'
import { TranslateIcon } from './TranslateIcon'
import { usePlayerController } from '../../context/PlayerContext'
import { useAppConfig } from '../../context/ConfigContext'
import { useTranslatorController } from '../../context/TranslatorContext'
import { getLanguageUrlParameter } from '../../utils/urlParameters'

const Container = styled.div`
  position: relative;
  line-height:20px;
`

const Button = styled.button<{
  loading: boolean
  buttonBackgroundColor: string
  buttonForegroundColor:string
  marginBottom: string
  useAlternativeTranslateButtonStyle:boolean
  extraStyles?:string
}>`
  cursor: pointer;
  ${(props) => props.loading && 'cursor: wait;'}
  background-color: ${(props) => props.useAlternativeTranslateButtonStyle ? "#FFFFFF00;":props.buttonBackgroundColor};
  border: none;
  width: 150px;
  border-radius: 16px;
  place-self: center;
  padding: ${(props) => props.useAlternativeTranslateButtonStyle ? "4px 1px;":"4px 10px;"}
  line-height:20px;
  margin-bottom: ${(props) => props.marginBottom};
  &:disabled {
    cursor: not-allowed;
  }
  &:hover {
    opacity: 0.7;
    ${(props) => props.loading && 'opacity: 1;'}
  }
  ${(props)=>props.extraStyles}
`

const Main = () => {
  const { status } = usePlayerController()

  const {
    buttonForegroundColor,
    buttonBackgroundColor,
    controlPanelBackgroundColor,
    translationButtonMarginBottom,
    autoTranslationEnabled,
    translationEnabled,
    extraCSSForTranslateButton,
    useLocalStorageForTranslationLanguage,
    translationLanguageUrlParameterEnabled,
    useAlternativeTranslateButtonStyle
  } = useAppConfig()

  const translatorController = useTranslatorController()

  // TODO: Replace timeout
  function autoTranslate ():void {
    if(autoTranslationEnabled && !useLocalStorageForTranslationLanguage){
      console.warn(
        `Warning: autoTranslationEnabled requires useLocalStorageForTranslationLanguage:true -flag to work, 
        currently the latter is ${useLocalStorageForTranslationLanguage}`);
    }
    if(translationLanguageUrlParameterEnabled && getLanguageUrlParameter()){
      console.warn(
        `Warning: not autotranslated because urlparameter for voiceintuitive was found: ${getLanguageUrlParameter()}`
      )
      return;
    }
    if (
      translationEnabled &&
      autoTranslationEnabled &&
      !translatorController.isTranslated &&
      localStorage.getItem('voiceIntuitiveTranslationLanguage')
    ) {
      setTimeout(() => {
        void translatorController.translate(localStorage.getItem('voiceIntuitiveTranslationLanguage') as string)
      }, 1000)
    }
  }

  autoTranslate()

  const [dropdownOpen, setDropdownOpen] = useState(false)

  const toggleDropdown = () => {
    if (status === 'loading') {
      return
    }
    setDropdownOpen(true)
  }
  
  return (
    <Container>
      <Button

        extraStyles={extraCSSForTranslateButton}
        loading={status === 'loading'}
        disabled={status === 'error'}
        marginBottom={translationButtonMarginBottom}
        buttonBackgroundColor={
          dropdownOpen ? controlPanelBackgroundColor : buttonBackgroundColor
        }
        buttonForegroundColor={buttonForegroundColor}
        useAlternativeTranslateButtonStyle={useAlternativeTranslateButtonStyle}
        onClick={() => toggleDropdown()}
        aria-label={"Translate"}
        aria-expanded={dropdownOpen}
        lang={"en"}

      >
        <TranslateIcon 
          color={useAlternativeTranslateButtonStyle ? buttonBackgroundColor:buttonForegroundColor} 
          alternativeStyle={useAlternativeTranslateButtonStyle} />
      </Button>
      {dropdownOpen && <LanguageDropdown setDropdownOpen={setDropdownOpen} wrapWithShadow={true}/>}
    </Container>
  )
}
export default Main
