import { h } from 'preact'
import { PlaylistTab } from './PlaylistTab'
import { useLocalizations } from '../../../localizations'
import { Headline, PlaylistHeader, PlaylistLinkContainer, PlaylistWrapper } from './Playlist.styles'
import { PlaylistCloseButton } from './PlaylistCloseButton'
import { useAppConfig } from '../../../context/ConfigContext'

interface Props {
  onClose: () => void
}
export function PlaylistPanel ({ onClose }: Props) {
  const { controlPanelForegroundColor, controlPanelBackgroundColor } =
    useAppConfig()
  const localizations = useLocalizations()

  return (
    <PlaylistWrapper backgroundColor={controlPanelBackgroundColor}>
      <PlaylistHeader>
        <PlaylistCloseButton onClose={onClose} color={controlPanelForegroundColor}/>
        <Headline foregroundColor={controlPanelForegroundColor}>
          {localizations.playlistTitle}
        </Headline>
      </PlaylistHeader>
      <PlaylistLinkContainer>
        <PlaylistTab/>
      </PlaylistLinkContainer>
    </PlaylistWrapper>
  )
}
