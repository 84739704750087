import { ssmlGenerator } from '../ssml/ssmlGenerator'
import { AppConfigurations } from '../../../context/ConfigContext'

export async function fetchIFrameContentsAsSsml (iframe: HTMLIFrameElement, config: AppConfigurations) {
  return ssmlGenerator(
    await readIframeContent(iframe, config) as Array<HTMLElement>,
    config.excludeText,
    config.voiceMode,
    config
  )
}

async function readIframeContent (iframe: HTMLIFrameElement, config: AppConfigurations) {
  const promise = new Promise((resolve, reject) => {
    function fetchData (retriesLeft = 100) {
      const doc = iframe?.contentWindow?.document
      if (!doc) {
        reject(new Error(
          'Iframe failed to appear when attempting to read content from an iframe.'
        ))
        return
      }

      // const ret = stupidReadBodyHack(docBody, config.iframeContentSelector ?? config.readAreaSelector) /// doc.querySelector(config.iframeContentSelector ?? config.readAreaSelector)
      const ret = doc.querySelectorAll(config.iframeContentSelector ?? config.readAreaSelector)
      if (ret) { resolve(Array.from(ret)) } else if (retriesLeft > 0) {
        console.warn(`Failed fetching content from iframe, retries left: ${retriesLeft}`)
        setTimeout(() => fetchData(retriesLeft - 1), 100)
      } else {
        reject(new Error('No content fetch retries left'))
      }
    }
    fetchData()
  })

  //
  return await promise
}
