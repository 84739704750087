import { PlaylistItem } from '../../readerServices/playlist/PlaylistItem'
import { PlaylistButton, PlaylistItemText } from './PlaylistTab.styles'
import { PlayingIcon } from './PlayingIcon'
import { PlayIcon } from './PlayIcon'
import { h } from 'preact'
import { Status } from '../../../models'
import { PlaylistRemoveButton } from './PlaylistRemoveButton'
import { useEffect, useRef } from 'preact/hooks'

interface Props {
  item: PlaylistItem
  color: string
  onPlay: () => Promise<void>
  onPause: () => Promise<void>
  onResume: () => Promise<void>
  onRemove: () => Promise<void>
  selected: boolean
  playerStatus: Status
}
export function PlaylistItemView ({ item, color, selected, playerStatus, onPlay, onResume, onPause, onRemove }: Props) {
  async function handleSelected () {
    if (selected) {
      if (playerStatus === 'playing') {
        await onPause()
      } else {
        await onResume()
      }
    } else {
      await onPlay()
    }
  }
  const ref = useRef<HTMLDivElement>(null)
  const el = ref.current
  useEffect(() => {
    if (selected && el) {
      el.scrollIntoView({ block: 'nearest' })
    }
  }, [selected, el])
  return (
      <div ref={ref} style={{ maxWidth: 768 - 20, display: 'flex' }}>
        <PlaylistButton
            foregroundColor={color}
            onClick={handleSelected}
        >
          {selected ? <PlayingIcon color={color} status={playerStatus}/> : <PlayIcon color={color}/>}
          <PlaylistItemText>
            {item.title}
          </PlaylistItemText>

        </PlaylistButton>
        <PlaylistRemoveButton color={color} onRemove={onRemove}/>
      </div>
  )
}
