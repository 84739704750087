import { h } from 'preact'
import styled from 'styled-components'
import { Status } from '../../../models'
import { useAppConfig } from '../../../context/ConfigContext'
import { usePlayerController } from '../../../context/PlayerContext'
import { ControlPanelPlayGlyph } from './ControlPanelPlayGlyph'
import { ControlPanelPlayShadow } from './ControlPanelPlayShadow'

const Svg = styled.svg<{ status: Status }>`
  path {
    ${(props) => props.status === 'disabled' && 'opacity: 0.3;'}
  }
  &:hover path,
  &:hover rect {
    opacity: 0.7;
    ${(props) => props.status === 'loading' && 'opacity: 1;'}
    ${(props) => props.status === 'error' && 'opacity: 1;'}
    ${(props) => props.status === 'disabled' && 'opacity: 0.3;'}
  }
`

export function ControlPanelPlay () {
  const { controlPanelBackgroundColor } = useAppConfig()
  const playerController = usePlayerController()

  return (
    <Svg
      width="90"
      height="90"
      viewBox="0 0 90 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      status={playerController.status}
    >
      <ControlPanelPlayShadow />
      <g filter="url(#filter0_d_120_379)">
        <circle cx="45" cy="44" r="32" fill={controlPanelBackgroundColor} />
        <ControlPanelPlayGlyph />
      </g>
    </Svg>
  )
}
