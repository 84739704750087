import { h } from 'preact'
import styled from 'styled-components'
import { useAppConfig } from '../../../context/ConfigContext'

const Svg = styled.svg`
  &:hover > path {
    opacity: 0.7;
  }
`

export function CrossSymbol () {
  const { controlPanelForegroundColor } = useAppConfig()

  return (
    <Svg
      width="12"
      height="12"
      viewBox="0 0 7 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.0757478 8.21635L2.82009 4.46566L0.0757478 0.696747C-0.0521665 0.477376 -0.0157093 0.221233 0.167205 0.0750901C0.350119 -0.0534527 0.642719 -0.016367 0.771262 0.184462L3.36915 3.71546L5.94912 0.184462C6.09526 -0.0166813 6.36963 -0.0534527 6.53401 0.0747759C6.75369 0.221233 6.79046 0.477062 6.66223 0.696747L3.89935 4.46566L6.66223 8.21635C6.79046 8.4178 6.75369 8.67363 6.53401 8.82009C6.47932 8.87509 6.38786 8.91155 6.31495 8.91155C6.16818 8.91155 6.02203 8.85655 5.94912 8.72863L3.36915 5.19763L0.771262 8.72863C0.698348 8.85655 0.551576 8.91155 0.423976 8.91155C0.350433 8.91155 0.258976 8.87509 0.167519 8.82009C-0.0157094 8.67363 -0.0521665 8.4178 0.0757478 8.21635Z"
        fill={controlPanelForegroundColor}
        fillOpacity="0.7"
      />
    </Svg>
  )
}
