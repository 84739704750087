import { clearHighlighting } from './highlightText'
import { WordMetaData } from '../audio/WordMetaData'

const punctuation = [' ', ':', '?', '!', ')', ']', "'", '"', ';']

export function createHighLightableSpans (
  element: Element,
  audioElement: HTMLAudioElement,
  metaData: WordMetaData,
  word: string
) {
  return findHighlightableSpans(element, word).map((childNode) => {
    // get the text of the element
    const sentence = childNode.textContent!.replaceAll("\xAD","") // null check done elsewhere

    // if whatever comes after the word is a punctuation mark, we add it to the end of the word span
    const terminator = findTerminator(sentence, word, metaData)

    // create the span
    const textSpan = createSpan(sentence, word, terminator, metaData, audioElement)
    // i think we can assume that whatever we are processing always has a parent
    childNode.parentNode!.insertBefore(textSpan, childNode)

    // remove crap from before the word from the text node
    childNode.textContent = trimContent(sentence, word, terminator)
    return textSpan
  })
}

function findTerminator (sentence: string, word: string, metaData: WordMetaData) {
  // figure out if this element has a word that matches the word we are looking for
  const match = sentence.indexOf(word)

  const wordEndIndex = match + metaData.wordlength

  // if whatever comes after the word is a punctuation mark, we add it to the end of the word span
  return punctuation.includes(sentence.charAt(wordEndIndex))
    ? sentence.charAt(wordEndIndex)
    : ''
}
function trimContent (sentence: string, word: string, terminator: string) {
  return sentence.substring(sentence.indexOf(word) + word.length).replace(terminator, '')
}
function createSpan (sentence: string, word: string, terminator: string, metaData: WordMetaData, audioElement: HTMLAudioElement) {
  const match = sentence.indexOf(word)
  // if match is not 0, then the word is not at the beginning of the sentence
  // so whatever comes before the word is added to the beginning of the word span
  let spanText =
      match === 0
        ? word
        : sentence.substring(0, match) + word

  // escaping HTML
  spanText = spanText.replace('<', '&#60;')
  spanText = spanText.replace('>', '&#62;')

  const textSpan = document.createElement('span')
  textSpan.innerHTML = spanText + terminator
  textSpan.style.fontFamily = 'inherit'
  textSpan.style.fontWeight = 'inherit'
  textSpan.dataset.voiceIntuitiveTimestamp = String(
    metaData.audiooffset / 10000000
  )
  // doesnt seem to be in use & is wrong in any case
  // textSpan.dataset.voiceIntuitiveDuration = String(
  //  metaData.audiooffset / 10000000
  // )

  // add an event listener
  textSpan.addEventListener('click', (event) =>
    setPlaybackPosition(event, audioElement)
  )
  return textSpan
}

function findHighlightableSpans (element: Element, word: string) {
  const ret = [...element.childNodes]
    .filter(isNonEmptyTextNode)
    .filter(n => n.textContent?.includes(word))
  return ret
}
function isNonEmptyTextNode (node: ChildNode) {
  return node.nodeName === '#text' && node.textContent?.trim()
}
// this function starts playback at the clicked element
function setPlaybackPosition (
  event: MouseEvent,
  audioElement: HTMLAudioElement
) {
  // clear highlighting
  clearHighlighting()

  audioElement.currentTime =
      // @ts-expect-error
      event.target.dataset.voiceIntuitiveTimestamp
}
