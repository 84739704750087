import { h } from 'preact'
import styled from 'styled-components'
import { useLocalizations } from '../../../localizations'
import { usePlayerController } from '../../../context/PlayerContext'
import { CrossSymbol } from '../symbols/CrossSymbol'

const Button = styled.button`
  position: absolute;
  top: 7px;
  right: 7px;
  cursor: pointer;
  background-color: transparent;
  padding: 0;
  border: none;
`
interface Props {
  onClose?: () => void
}
export function CloseButton ({ onClose }: Props) {
  const playerController = usePlayerController()
  const localizations = useLocalizations()
  return (
    <Button
      aria-label={localizations.close}
      onClick={() => onClose ? onClose() : playerController.setWidgetOpen(false)}
    >
      <CrossSymbol />
    </Button>
  )
}
