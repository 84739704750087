import { h } from 'preact'
import styled from 'styled-components'
import { useAppConfig } from '../../../context/ConfigContext'
import { usePlayerController } from '../../../context/PlayerContext'
import { useLocalizations } from '../../../localizations'
import { SpeedSelector } from '../SpeedSelector'

const Container = styled.div`
  display: flex;
  place-self: center end;
`

const Button = styled.button`
  cursor: pointer;
  background-color: transparent;
  padding: 0;
  border: none;
  place-self: center end;
  &:hover {
    opacity: 0.7;
  }
`

const Label = styled.span<{ foregroundColor: string }>`
  font-family: quicksand;
  margin-right: 2px;
  border: none;
  font-size: 16px;
  color: ${(props) => props.foregroundColor};
`

export function ToggleSpeedSelectorButton () {
  const { controlPanelForegroundColor } = useAppConfig()
  const playerController = usePlayerController()
  const localizations = useLocalizations()

  return (
    <Container>
      {playerController.speedSelectorOpen && (
        <SpeedSelector setSpeedSelectorIsOpen={(open: boolean) => playerController.setSpeedSelectorOpen(open)} />
      )}
      <Button
        aria-controls="speedSelector"
        aria-expanded={playerController.speedSelectorOpen}
        aria-label={localizations.speedSelector}
        onClick={() => playerController.setSpeedSelectorOpen(!playerController.speedSelectorOpen)}
      >
        <Label foregroundColor={controlPanelForegroundColor}>
          {playerController.playbackSpeed.toFixed(1)}x
        </Label>
      </Button>
    </Container>
  )
}
