import { ExtractedSentence } from './extractSsmlAndInjectOffsets'
import { addPhonemes, PhoneticWords } from './addPhonemes'
import { toBreak } from './breakHelpers'
import { handleSpelledOutWords } from './handleSpelledOutWords'


export interface SsmlBlock {
  ssml: string
  sentenceId?: string
}

export function toSsmlBlocks (
  sentences: ExtractedSentence[],
  lang:string,
  phoneticWords: PhoneticWords = {},
  speechCustomizationWordsToSpellOut:string[]=[]): SsmlBlock[] {
  
    return sentences.map(s => toSsmlBlock(s, phoneticWords,speechCustomizationWordsToSpellOut,lang))
}

function toSsmlBlock (
  { words, sentenceId }: ExtractedSentence, 
  phoneticWords: PhoneticWords,
  spellOutWords:string[],
  lang:string): SsmlBlock {  
    return {
      sentenceId,
      ssml: words.map(w => toSsml(w, phoneticWords,spellOutWords,lang)).join(' ')
    }
}

function toSsml (word: string, phoneticWords: PhoneticWords,spellOutWords:string[],lang:string) {
  if(toBreak(word) !== null) return toBreak(word);
  if(addPhonemes(word,lang,phoneticWords) !== word) return addPhonemes(word,lang,phoneticWords);
  
  return handleSpelledOutWords(word,spellOutWords)
}
