// here be dragons

import { Configurations, Exclusions } from '../../../models'
import { isExcludedElement } from './isExcludedElement'
import {
  BREAK_SENTENCE_MEDIUM,
  getTrailingBreak,
  removeExtraBreaksFromSentences,
  removeExtraBreaksFromWords
} from './breakHelpers'
import { toSsmlBlocks } from './toSsmlBlocks'
import { injectOffsets } from './injectOffsets'
import { escapeSSMLReservedChars } from './SSMLUtils'
import { mergeDeepImmutable } from '../../../utils/deepMergeImmutable'

export function extractSsmlAndInjectOffsets (
  element: HTMLElement,
  exclusions: Exclusions,
  initialOffset: number,
  appConfig:Configurations,
  lang:string
) {

  const combinedExclusions = mergeDeepImmutable(appConfig.globalExcludeText,exclusions);

  const rawSentences = 
    extractSentencesFromElement(
      element, 
      combinedExclusions, 
      hasSentenceSpans(element))
  
  const sentences = 
    removeExtraBreaksFromSentences(
      rawSentences
        //remove soft hyphens
        .map(rawSentence=>(
          {
            ...rawSentence,
            words:rawSentence.words.map(word=>word.replaceAll("\xAD",""))
          })))
          
  const ssmlBlocks = toSsmlBlocks(sentences,lang,appConfig.phonemes,appConfig.speechCustomizationWordsToSpellOut)
  injectOffsets(ssmlBlocks, initialOffset)

  return ssmlBlocks.map(b => b.ssml).join(' ')
}
export interface ExtractedSentence {
  words: string[]
  sentenceId?: string
  break?: boolean
}

/*
* Note: this function is called many times on the elements for some reason
*
*/
function extractSentencesFromElement (element: HTMLElement, exclusions: Exclusions, useSentenceSpans: boolean): ExtractedSentence[] {
  if (element.nodeType === 3 && !useSentenceSpans) {
    return [{
      words: extractWords(element,exclusions.singleWords)
    }]
  }
  if (element.nodeName === 'BR') { return [BREAK_SENTENCE_MEDIUM] }
  if (isExcludedElement(element, exclusions)) { return [BREAK_SENTENCE_MEDIUM] }

  const sentenceId = findSentenceId(element)
  if (sentenceId) {
    return [{
      words: extractWords(element,exclusions.singleWords), sentenceId
    }]
  }

  const ret = [...element.childNodes]
    .filter(n => n instanceof HTMLElement || !useSentenceSpans)
    .flatMap(n => extractSentencesFromElement(n as HTMLElement, exclusions, useSentenceSpans))
  const trailingBreak = getTrailingBreak(element)
  if (trailingBreak) { ret.push({ words: [trailingBreak], break: true }) }

  return ret
}

function extractWords (element: HTMLElement,excludedWords:string[]=[]): string[] {
  const words = 
    (element.textContent?.split(/\s+/g) ?? [])
      .filter((word:string)=>!excludedWords.includes(word))
  return removeExtraBreaksFromWords(words.filter(Boolean).map(escapeSSMLReservedChars))
}

function findSentenceId (element: HTMLElement) {
  // @kludge
  if (!element.getAttribute) { return }
  return element.getAttribute('data-voice-intuitive-sentence')
}
function hasSentenceSpans (element: HTMLElement) {
  return !!element.querySelector('[data-voice-intuitive-sentence]')
}
