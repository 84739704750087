import { h } from 'preact'
import { useEffect, useRef } from 'preact/hooks'
import styled from 'styled-components'
import { useHideOnClickOutside } from '../../hooks'
import Slider from 'rc-slider'
import GlobalSliderStyle from '../../assets/rc-slider'
import { useAppConfig } from '../../context/ConfigContext'
import { usePlayerController } from '../../context/PlayerContext'
import { useLocalizations } from '../../localizations'

interface SpeedSelectorProps {
  setSpeedSelectorIsOpen: (open: boolean) => void
}

const SpeedSelectorContainer = styled.div<{ backgroundColor: string }>`
  position: fixed;
  font-family: quicksand;
  bottom: 60px;
  right: 0;
  z-index: 100;
  border-radius: 8px;
  box-shadow: 0px 0px 25px #a9a9a9;
  background-color: ${(props) => props.backgroundColor};
  @media (max-width: 450px) {
    display: none;
  }
`

const InnerContainer = styled.div`
  margin-bottom: 10px;
  padding: 10px;
  height: 180px;
  touch-action: none;
`

const Close = styled.button<{ backgroundColor: string }>`
  position: absolute;
  height: 24px;
  padding: 7px;
  width: 24px;
  right: -11px;
  top: -11px;
  border-radius: 50%;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 25px #a9a9a9;
  background-color: ${(props) => props.backgroundColor};
  cursor: pointer;
`

const Title = styled.p<{ foregroundColor: string }>`
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  margin: 10px 15px;
  color: ${(props) => props.foregroundColor};
  white-space: nowrap;
`

const SpeedLabel = styled.span<{ foregroundColor: string }>`
  font-family: quicksand;
  cursor: pointer;
  font-size: 15px;
  padding: 8px 6px;
  color: ${(props) => props.foregroundColor};
`

export function SpeedSelector (props: SpeedSelectorProps) {
  const { setSpeedSelectorIsOpen } = props
  const {
    controlPanelForegroundColor,
    controlPanelBackgroundColor
  } = useAppConfig()

  const playerController = usePlayerController()
  const localizations = useLocalizations()

  // this hides the component when it is not clicked
  const { ref } = useHideOnClickOutside(false, setSpeedSelectorIsOpen)

  const marks = {
    2.0: (
      <SpeedLabel foregroundColor={controlPanelForegroundColor}>
        2.0x
      </SpeedLabel>
    ),
    1.0: (
      <SpeedLabel foregroundColor={controlPanelForegroundColor}>
        1.0x
      </SpeedLabel>
    ),
    0.5: (
      <SpeedLabel foregroundColor={controlPanelForegroundColor}>
        0.5x
      </SpeedLabel>
    )
  }

  const lightForegroundColor = controlPanelForegroundColor + '44'

  const sliderRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (sliderRef.current !== null) sliderRef.current.focus()
  }, [ref])

  return (
    <SpeedSelectorContainer
      backgroundColor={controlPanelBackgroundColor}
      id={'speedSelector'}
      ref={ref}
    >
      
      <Title foregroundColor={controlPanelForegroundColor}>
        {localizations.playbackSpeedTitle}
      </Title>
      <InnerContainer>
        <Slider
          ref={sliderRef}
          vertical
          min={0.5}
          max={2.0}
          marks={marks}
          step={0.1}
          onChange={(speed: number) => playerController.changePlaybackSpeed(speed)}
          defaultValue={playerController.playbackSpeed}
          style={{
            marginLeft: 'calc(50% - 22px)'
          }}
          dotStyle={{
            borderColor: lightForegroundColor,
            backgroundColor: controlPanelBackgroundColor,
            width: 8,
            height: 8,
            marginLeft: -3
          }}
          activeDotStyle={{ borderColor: controlPanelForegroundColor }}
          trackStyle={{
            backgroundColor: controlPanelForegroundColor,
            width: 2
          }}
          railStyle={{ backgroundColor: lightForegroundColor, width: 2 }}
          handleStyle={{
            borderColor: controlPanelForegroundColor,
            borderWidth: 2,
            backgroundColor: controlPanelForegroundColor,
            opacity: 1,
            marginLeft: -7,
            width: 16,
            height: 16
          }}
        />
        <GlobalSliderStyle />
      </InnerContainer>
      <Close
        aria-label={localizations.playbackSpeedClose}
        backgroundColor={controlPanelBackgroundColor}
        onClick={() => setSpeedSelectorIsOpen(false)}
      >
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 13 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.52801 12.232C1.29334 12.232 1.08001 12.136 0.888006 11.944C0.696006 11.752 0.600006 11.528 0.600006 11.272C0.600006 11.016 0.696006 10.792 0.888006 10.6L10.584 0.967993C10.776 0.775993 11.0107 0.679993 11.288 0.679993C11.544 0.679993 11.7573 0.775993 11.928 0.967993C12.12 1.15999 12.216 1.37333 12.216 1.60799C12.216 1.84266 12.12 2.07733 11.928 2.31199L2.23201 11.912C2.01867 12.1253 1.78401 12.232 1.52801 12.232ZM10.488 11.944L1.14401 2.34399C0.97334 2.13066 0.888006 1.90666 0.888006 1.67199C0.888006 1.41599 0.97334 1.20266 1.14401 1.03199C1.31467 0.839994 1.51734 0.743994 1.75201 0.743994C2.02934 0.743994 2.26401 0.85066 2.45601 1.06399L11.768 10.632C11.9813 10.824 12.088 11.048 12.088 11.304C12.088 11.624 11.9813 11.8587 11.768 12.008C11.5547 12.1573 11.352 12.232 11.16 12.232C10.9467 12.232 10.7227 12.136 10.488 11.944Z"
            fill={controlPanelForegroundColor}
          />
        </svg>
      </Close>
    </SpeedSelectorContainer>
  )
}
