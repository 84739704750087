import emptyAudioFile from '../assets/emptyAudioFile'
import { clearHighlighting } from '../reader/readerServices/highlight/highlightText'
import { h, render } from 'preact'
import { App } from '../App'
import { LANGUAGE_KEY } from '../translator/translatorServices/translate'
import { domManipulator } from '../services/domManipulator'
import { DetachedLanguageDropdown } from '../translator/components/DetachedLanguageDropdown'
import { PlayListController } from '../reader/readerServices/playlist/PlayListController'
import { TranslatorController } from '../translator/TranslatorController'
import { PlayerController } from '../reader/readerServices/audio/PlayerController'
import { AppConfigurations } from '../context/ConfigContext'
import { PlaylistItemProps } from '../reader/readerServices/playlist/PlaylistItem'
import { injectAddToPlaylist, InjectAddToPlaylistProps } from './injectAddToPlaylist'

export type ViEvent = CustomEvent<{ name?: string, args?: any[] }>

const TRANSLATE_MENU_ID = 'voice-intuitive-translate-page-menu'

export class EventHandlers {
  private config: AppConfigurations
  private element: HTMLElement
  private playerController: PlayerController
  private playlistController: PlayListController
  private translatorController: TranslatorController
  private prevLanguage: string | null

  constructor (config: AppConfigurations,
    element: HTMLElement | undefined,
    playerController: PlayerController,
    playlistController: PlayListController,
    translatorController: TranslatorController) {
    this.config = config
    this.playerController = playerController
    this.playlistController = playlistController
    this.translatorController = translatorController
  }

  createEventListeners (element: HTMLElement) {
    if (this.element === element) { return }
    this.element = element
    element.addEventListener(
      'widget-event',
      (e: ViEvent) => {
        const eventName = e.detail.name
        if (!eventName) {
          console.error('missing event name')
          return
        }
        void this.onEvent(eventName, e, e.detail.args)
      }
    )
  }

  async onEvent (eventName: string, event: ViEvent, args?: any[]) {
    switch (eventName) {
      case 'reset':
        return this.onReset()
      case 'unmount':
        return this.onUnmount()
      case 'mount':
        return this.onMount()
      case 'retranslate':
        return await this.onRetranslate()
      case 'translate':
        return await this.onTranslate(args)
      case 'translate-page':
        return await this.onTranslate(args, true)
      case 'show-translate-menu':
        return this.onShowTranslateMenu(event, args)
      case 'close':
        this.playerController.setWidgetOpen(false)
        return
      case 'open':
        this.playerController.setWidgetOpen(true)
        return
      case 'initplaying':
        this.playerController.init()
        return
      case 'play':
        console.log("Voiceintuitive API play call: If playing doesn't start, call 'initplaying' first");
        this.playerController.play();
        return
      case 'stop':
        this.playerController.stop()
        return
      case 'pause':
        this.playerController.pause()
        return
      case 'skip15+':
        this.playerController.skip(15)
        return;
      case 'skip15-':
        this.playerController.skip(-15)
        return;
      case 'changeFastPlaybackSpeed':
        this.playerController.changePlaybackSpeed(1.2);
        return;
      case 'changeNormalPlaybackSpeed':
        this.playerController.changePlaybackSpeed(1);
        return;
      case 'injectAddToPlaylist':
        if (!args) { throw new Error('no arguments provided') }
        injectAddToPlaylist(args[0] as InjectAddToPlaylistProps)
        this.playlistController.updatePlaylistItemIndicators()
        return
      case 'addToPlaylist':
        this.playlistController.pushAll(args as PlaylistItemProps[])
        this.playlistController.setPlaylistWidgetOpen(true)
        return
      case 'togglePlaylist':
        this.playlistController.toggleAll(args as PlaylistItemProps[])
        this.playlistController.setPlaylistWidgetOpen(!this.playlistController.playlist.isEmpty())
        return
      case 'storePageContent':
        return domManipulator.hijack(document.querySelector('body'))
      case 'restorePageContent':
        return domManipulator.restore()

      default:
        console.error('unknown event', eventName)
    }
  }

  onReset () {
    this.playerController.setStatus('uninitialized')
    if(this.translatorController){
      //NOTE: this stops the playback also
      this.translatorController.resetLanguage();
    }
    domManipulator.restore()
    //NOTE: TODO: no idea if the latter code works in some combination, usually errors out
    // @ts-expect-error this property does exist
    audioPlayer.current!.src = emptyAudioFile(this.config.useFileAsEmptySoundbite ? true:false)
    clearHighlighting()
    console.log('Voice Intuitive widget was reset.')
  }

  onUnmount () {
    clearHighlighting()

    const listItems = this.getWrappingElement().getElementsByTagName('DIV')
    const listArray = [...listItems]

    listArray.forEach((item) => {
      if (!item.shadowRoot || !item.shadowRoot.firstElementChild) {
        return
      }
      render(null, item.shadowRoot.firstElementChild)
    })

    console.log('Voice Intuitive widget was unmounted.')
  }

  onMount () {
    const newDiv = document.createElement('div')
    const innerWrapper = 
     this.config.prependButtonsInButtonContainer ? 
        (this.getWrappingElement().prepend(
          newDiv
        ),newDiv):
        this.getWrappingElement().appendChild(
          newDiv
        )
    const shadow = innerWrapper.attachShadow({ mode: 'open' })
    const targetElement: HTMLElement = shadow.appendChild(
      document.createElement('div')
    )

    render(
      h(App, { ...this.config, element: targetElement }),
      targetElement
    )
    domManipulator.restore()
    
    console.log('Voice Intuitive widget was mounted.')
  }

  async onRetranslate () {
    await this.onTranslate([this.prevLanguage], true)
  }

  async onTranslate (args: any[] = [], entirePage = false) {
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    this.translatorController.resetLanguage()
    const [lang] = args
    if (lang) { localStorage.setItem(LANGUAGE_KEY, lang) }
    const languageCode = localStorage.getItem(LANGUAGE_KEY)
    this.prevLanguage = languageCode
    if (!languageCode) {
      console.warn(
        'Translation failed: no target language option was detected in localStorage.'
      )
      return
    }

    await this.translatorController.translate(languageCode, entirePage)
    console.log(`Successfully translated to ${languageCode}`)
  }

  onShowTranslateMenu (event: ViEvent, args: any[] = []) {
    const mounted = document.querySelector('#' + TRANSLATE_MENU_ID)
    if (mounted) {
      if (!(mounted instanceof HTMLElement)) {
        console.error(mounted)
        throw new Error('detached menu found but not a HTMLElement')
      }
      return mounted
    }
    //note, the "event" here isn't a real event, and the "args" are the arguments passed to the original event call 
    let parent:HTMLElement|string|null = null;
    if(args[0]){
      if(args[0] instanceof HTMLElement){
        parent = args[0]
      }
      if(typeof(args[0])==="string"){
        parent =<HTMLElement>document.querySelector(args[0])
      }
    }
    if(this.config.translateMenuParentSelector){
      
      parent = findTranslateMenuParent(this.config.translateMenuParentSelector);
    }
    if(!parent)
      console.error("Can't open detached translate menu, no parent found, call command with _('show-translate-menu',this) for example or refer to the installation guide");
    const wrapper = parent!.appendChild(
      document.createElement('div')
    )
    wrapper.setAttribute('id', TRANSLATE_MENU_ID)

    const targetElement: HTMLElement = wrapper.attachShadow({ mode: 'open' }).appendChild(
      document.createElement('div')
    )

    render(
      h(DetachedLanguageDropdown, {
        config: this.config,
        element: targetElement,
        unmount: () => wrapper.remove(),
        onTranslate: async lang => await this.onTranslate([lang], true),
        onReset: () => this.translatorController.resetLanguage()
      }),
      targetElement
    )
    return targetElement
  }

  getWrappingElement () {
    const wrappingElement = document.querySelector(
      this.config.buttonContainerSelector ?? '#voice-intuitive-root'
    )

    if (!wrappingElement) {
      throw new Error('Voice Intuitive root element not found.')
    }
    return wrappingElement
  }

  setConfig (config: AppConfigurations) {
    this.config = config
  }

  setPlayerController (playerController: PlayerController) {
    this.playerController = playerController
  }

  setPlayListController (playlistController: PlayListController) {
    this.playlistController = playlistController
  }

  setTranslatorController (translatorController: TranslatorController) {
    this.translatorController = translatorController
  }
}

function findTranslateMenuParent (selector?: string): HTMLElement {
  if (!selector) { throw new Error('translateMenuParentSelector not set in config') }
  const ret = document.querySelector(selector)
  if (!ret) {
    throw new Error(`translate menu parent not found:${selector}`)
  }
  if (ret instanceof HTMLElement) {
    return ret
  } else {
    throw new Error(`translate menu parent is not a HTMLElement:${selector}`)
  }
}
