import { PlaylistItem, PlaylistItemProps, toPlaylistItem } from './PlaylistItem'
import { usePersistentJsonState } from '../../../hooks/usePersistentState'
import { useState } from 'preact/hooks'

/**
 * Immutable playlist
 */
export class Playlist {
  constructor (
    readonly items: PlaylistItem[] = [],
    readonly position: number = -1
  ) {}

  next () {
    if (!this.hasNext()) { return this }
    return new Playlist(this.items, this.position + 1)
  }

  prev () {
    if (!this.hasPrev()) { return this }
    return new Playlist(this.items, this.position - 1)
  }

  hasNext () {
    return this.position < this.items.length - 1
  }

  hasPrev () {
    return this.position > 0
  }

  isEmpty () {
    return this.items.length === 0
  }

  clearAll () {
    return new Playlist()
  }

  clearOld () {
    const items = this.items.slice(this.position)
    return new Playlist(items, 0)
  }

  setPosition (position: number) {
    if (position < -1 || position >= this.items.length) {
      console.warn(`invalid position ${position}`)
      return this
    }
    return new Playlist(this.items, position)
  }

  getCurrentItem () {
    return this.items[this.position]
  }

  push (itemProps: PlaylistItemProps) {
    return this.pushItem(toPlaylistItem(itemProps))
  }

  pushItem (item: PlaylistItem) {
    return new Playlist([...this.items, item], this.position)
  }

  remove (index: number) {
    const items = [...this.items.slice(0, index), ...this.items.slice(index + 1)]
    const position = this.position >= items.length ? items.length - 1 : this.position
    return new Playlist(items, position)
  }

  pushAll (items: PlaylistItemProps[]) {
    return new Playlist([...this.items, ...items.map(toPlaylistItem)], this.position)
  }

  toggleAll (items: PlaylistItemProps[]) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let pl: Playlist = this
    for (const item of items) {
      pl = pl.toggle(toPlaylistItem(item))
    }
    return pl
  }

  toggle (item: PlaylistItem) {
    if (this.contains(item)) {
      return this.removeItem(item)
    } else {
      return this.addItem(item)
    }
  }

  contains (item: PlaylistItem) {
    return !!this.items.find((i) => i.id === item.id)
  }

  removeItem (item: PlaylistItem) {
    const firstRemovedIndex = indexOf(this.items, item)
    if (firstRemovedIndex < 0) { return this }
    const curItem = this.getCurrentItem()
    const newItems = this.items.filter((i) => i.id !== item.id)
    let newPosition
    if (curItem?.id === item.id) {
      newPosition = firstRemovedIndex - 1
    } else {
      newPosition = indexOf(newItems, curItem)
    }

    return new Playlist(newItems, newPosition)
  }

  addItem (item: PlaylistItem) {
    return new Playlist([...this.items, item], this.position)
  }

  isLastPosition () {
    return this.position >= 0 && this.position === this.items.length - 1
  }

  getCurrentOrFirstItem () {
    if (this.position < 0) { return this.items[0] }
    return this.getCurrentItem()
  }

  size () {
    return this.items.length
  }
}

function indexOf (items: PlaylistItem[], item?: PlaylistItem) {
  if (!item) { return -1 }
  // we care about the index
  // eslint-disable-next-line @typescript-eslint/no-for-in-array
  for (const i in items) {
    if (items[i].id === item.id) { return parseInt(i) }
  }
  return -1
}

export function usePlaylist () {
  // persisting this causes issues, so making it ephemeral for now
  // const [playlistPosition, setPlaylistPosition] = usePersistentIntState('playlistPosition', -1)
  const [playlistPosition, setPlaylistPosition] = useState(-1)
  const [playlistItems, setPlaylistItems] = usePersistentJsonState<PlaylistItem[]>('playlistItems', [])
  return {
    playlist: new Playlist(playlistItems, playlistPosition),
    updatePlaylist (playlist: Playlist) {
      setPlaylistItems(playlist.items)
      setPlaylistPosition(playlist.position)
    }
  }
}
