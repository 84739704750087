const PARAMETER = "vi-language"

export const  setLanguageUrlParameter = (value:string):void =>{
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set(PARAMETER,value);
    window.history.replaceState({}, '', `${window.location.pathname}?${urlParams}`);
}
export const  getLanguageUrlParameter = ():string =>{
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(PARAMETER) || "";
}
export const  clearLanguageUrlParameter = ():void =>{
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.delete(PARAMETER);
    window.history.replaceState({}, '', `${window.location.pathname}?${urlParams}`);
}
export const  getNewUrlWithLanguageUrlParameter = (value:string):string =>{
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set(PARAMETER,value);
    return `${window.location.pathname}?${urlParams}`;
}
